import { Box, Stack, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash';

// @ts-expect-error - js module
import useGetTbwInsights from '@/pages/TaskBasedWorkflow/hooks/useGetTbwInsights';
import GoalBanner from '@/components/GoalTiles/GoalBanner';
import { InternalPerformanceMetric } from '@/components/GoalTiles/types';
import mod from '@/utils/mod';
import useGetGoal from '@/components/GoalTiles/hooks/useGetGoal';
import useTimestampCookie from '@/hooks/useTimestampCookie';

import { ReactComponent as LeftIcon } from './assets/left.svg';
import { ReactComponent as RightIcon } from './assets/right.svg';
// @ts-expect-error - js module
import WidgetVisits from './WidgetVisits';

const WIDTH = 405;
const HEIGHT = 120;
const TRANSITION_TIMEOUT = 300;

const ArrowButton = styled('button')`
  padding: ${(p) => p.theme.spacing(0.5)};
  color: ${(p) => p.theme.palette.icon.placeholder};

  &:hover {
    color: ${(p) => p.theme.palette.icon.link};
  }

  svg {
    display: block;
  }
`;

type CarouselProps = {
  $activeIndex: number;
};

const Carousel = styled(Stack)<CarouselProps>(({ theme, $activeIndex }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transform: `translateX(${-$activeIndex * WIDTH}px)`,
  transition: `transform ${TRANSITION_TIMEOUT}ms ${theme.transitions.easing.easeInOut}`,
}));

const CarouselItem = styled('div')`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  display: flex;
  justify-content: center;

  &.exit-done {
    visibility: hidden;
  }
`;

export default function WidgetVisitsCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  const { isSuccess: isSuccessInsights } = useGetTbwInsights();
  const { isSuccess: isSuccessGoal } = useGetGoal(
    InternalPerformanceMetric.acv,
  );
  const [hasSeenAnimation, setHasSeenAnimation] = useTimestampCookie(
    'has_seen_visits_carousel_animation',
  );
  const timeoutsRef = useRef<Array<ReturnType<typeof setTimeout>>>([]);

  useEffect(() => {
    if (isSuccessInsights && isSuccessGoal && !hasSeenAnimation) {
      setHasSeenAnimation();
      // Make the carousel scroll on page load to show the user that it's interactive.
      timeoutsRef.current.push(
        setTimeout(() => {
          setActiveIndex((x) => x + 1);
        }, 2000),
      );
      timeoutsRef.current.push(
        setTimeout(() => {
          setActiveIndex((x) => x - 1);
        }, 3000),
      );
    }
  }, [hasSeenAnimation, isSuccessGoal, isSuccessInsights, setHasSeenAnimation]);

  useEffect(() => {
    // Remove timeouts if the component unmounts.
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutsRef.current.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  // The items inside the carousel
  const items = [
    <GoalBanner
      sx={{ height: '100%', width: '100%' }}
      internalPerformanceMetric={InternalPerformanceMetric.acv}
      title="Monthly Goal"
      small
      noBorder
    />,
    <WidgetVisits sx={{ height: '100%', width: '100%' }} inCarousel />,
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const nodeRefs = range(items.length).map(() => useRef(null));

  return (
    <Stack direction="row" alignItems="center">
      <ArrowButton
        onClick={() => setActiveIndex((x) => mod(x - 1, items.length))}
        sx={activeIndex === 0 ? { opacity: 0, pointerEvents: 'none' } : null}
      >
        <LeftIcon />
      </ArrowButton>

      <Box
        width={WIDTH}
        height={HEIGHT}
        position="relative"
        overflow="hidden"
        mx={2}
      >
        <Carousel direction="row" $activeIndex={activeIndex}>
          {items.map((node, index) => (
            <CSSTransition
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              nodeRef={nodeRefs[index]}
              in={index === activeIndex}
              timeout={TRANSITION_TIMEOUT}
            >
              <CarouselItem ref={nodeRefs[index]}>{node}</CarouselItem>
            </CSSTransition>
          ))}
        </Carousel>
      </Box>

      <ArrowButton
        onClick={() => setActiveIndex((x) => mod(x + 1, items.length))}
        sx={
          activeIndex === items.length - 1
            ? { opacity: 0, pointerEvents: 'none' }
            : null
        }
      >
        <RightIcon />
      </ArrowButton>
    </Stack>
  );
}
