import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';

import themeV2 from '@/themeV2';
import VisitsDxHistoryTabularTable from '@/pages/PatientSummary/components/VisitsDxHistory/VisitsDxHistoryTabularTable';
import { StyledModalLink } from '@/pages/PatientSummary/components/VisitsDxHistory/styles';
import BaseModal from '@/components/BaseModal';

const StyledPatientHistoryModal = styled(BaseModal)`
  min-width: 600px;
  max-width: 1200px;
`;

function VisitsDxHistoryTabularTableModal({ patientData, patientId }) {
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const handleOpenHistoryModal = () => {
    setIsHistoryModalOpen(true);
  };

  const handleCloseHistoryModal = () => {
    setIsHistoryModalOpen(false);
  };

  return (
    <ThemeProvider theme={themeV2}>
      <div>
        <StyledPatientHistoryModal
          open={isHistoryModalOpen}
          onClose={handleCloseHistoryModal}
          aria-labelledby="history-modal-title"
          aria-describedby="history-modal-description"
          title=""
        >
          <VisitsDxHistoryTabularTable
            patientData={patientData}
            patientId={patientId}
          />
        </StyledPatientHistoryModal>
        <StyledModalLink onClick={handleOpenHistoryModal}>
          {' '}
          3 year encounter and Dx history
        </StyledModalLink>
      </div>
    </ThemeProvider>
  );
}

VisitsDxHistoryTabularTableModal.propTypes = {
  patientData: PropTypes.object,
  patientId: PropTypes.number,
};

VisitsDxHistoryTabularTableModal.defaultProps = {
  patientData: null,
  patientId: null,
};

export default VisitsDxHistoryTabularTableModal;
