import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';

import BaseFormModal from '@/components/BaseFormModal';
import BaseLinkButton from '@/components/BaseLinkButton';
import {
  ANNUAL_VISIT,
  OUTREACH_CAMPAIGN_STATUS_ACTIVE,
  PRIORITY_PATIENT_VISIT,
  Page,
} from '@/pages/TaskBasedWorkflow/constants';
import { getPage } from '@/pages/TaskBasedWorkflow/contexts/PageContext';
import { ReactComponent as ShActiveCalling } from '@/assets/icons/shActiveCalling.svg';
import OutreachInfoBanner from '@/pages/TaskBasedWorkflow/components/patientDetailView/OutreachInfoBanner';
import { useStepExecutionContext } from '@/pages/TaskBasedWorkflow/components/patientDetailView/stepExecutionPanel/StepExecutionContext';
import OutreachStartModal from '@/pages/TaskBasedWorkflow/components/patientDetailView/OutreachStartModal';
import OutreachCampaignStopModal from '@/pages/TaskBasedWorkflow/components/patientDetailView/OutreachCampaignStopModal';
import OutreachCampaignModalConfirm from '@/pages/TaskBasedWorkflow/components/patientDetailView/OutreachCampaignModalConfirm';
import useStartOutreach from '@/pages/TaskBasedWorkflow/hooks/useStartOutreach';

import useLogOutreach from '../../hooks/useLogOutreach';
import useGetLogOutreach from '../../hooks/useGetLogOutreach';
import HistoryItem from '../HistoryItem';
import queryKeyFactory from '../../queryKeyFactory';

import PatientBannerButton from './PatientBannerButton';

const formFields = [
  {
    name: 'outcome',
    label: 'Outcome',
    type: 'radiogroup',
    required: true,
    choices: [
      ['talked_to_patient_or_caregiver', 'Talked to patient or caregiver'],
      ['left_message', 'Left message (voicemail, text, email)'],
      ['patient_asked_to_call_back', 'Patient asked to call back'],
      ['patient_declined_to_talk', 'Patient declined to talk'],
      ['phone_number_not_in_service', 'Phone number not in service'],
      ['unable_to_leave_message', 'Unable to leave message'],
    ],
    pendoIdPrefixer: (id) => `worklist-patient-detail-outreach-log-modal-${id}`,
  },
  {
    name: 'note',
    label: 'Note (optional)',
    type: 'textarea',
    required: false,
    minRows: 3,
    placeholder: 'Enter note',
    sx: {
      resize: 'vertical',
      padding: '4px 8px',
    },
    'data-pendo-id': 'worklist-patient-detail-outreach-log-modal-note',
  },
];

export default function OutreachLogModal({
  patientId,
  patientName,
  patientPhone,
}) {
  const { action: selectedAction } = useStepExecutionContext();
  const [showModal, setShowModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const { isLoading, mutate, reset } = useLogOutreach();
  const { isLoading: isSubmittingStartOutreach, mutate: mutateStartOutreach } =
    useStartOutreach();
  const { isLoading: isOutreachDataLoading, data: outreachData } =
    useGetLogOutreach({
      patientId,
    });
  const outreachHistory = outreachData?.outreaches;
  const outreachCampaign = outreachData?.campaign;
  const outreachEligibleSubtypes = [ANNUAL_VISIT, PRIORITY_PATIENT_VISIT];
  const selectedActionIsOutreachEligible =
    !selectedAction?.v2Dto?.dateOfService &&
    outreachEligibleSubtypes.includes(selectedAction?.subtype);
  const isVisitsPage = getPage() === Page.visits;
  const outreachIsEnabled =
    outreachCampaign !== undefined &&
    selectedActionIsOutreachEligible &&
    isVisitsPage;
  const campaignIsActive =
    outreachCampaign?.status === OUTREACH_CAMPAIGN_STATUS_ACTIVE;

  const handleBannerButtonClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    reset();
    setShowModal(false);
    setShowHistory(false);
  };

  const handleSubmit = (data) => {
    mutate({ patientId, ...data }, { onSuccess: handleClose });
  };

  const handleHistoryButtonClick = () => {
    setShowHistory((previousValue) => !previousValue);
  };

  const queryClient = useQueryClient();
  const refreshLogOutreach = async () => {
    await queryClient.invalidateQueries(
      queryKeyFactory.logOutreach({ patientId }),
    );
  };

  const [showStartModal, setShowStartModal] = useState(false);
  const [startModalErrorMessage, setStartModalErrorMessage] = useState('');
  const [showStartModalConfirm, setShowStartModalConfirm] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);
  const [showStopModalConfirm, setShowStopModalConfirm] = useState(false);

  const handleOpenStartModal = () => {
    handleClose();
    setShowStartModal(true);
  };

  const handleSubmitStartModalSuccess = async () => {
    setShowStartModal(false);
    setStartModalErrorMessage('');
    refreshLogOutreach();
    setShowStartModalConfirm(true);
    await queryClient.invalidateQueries(
      queryKeyFactory.patientTasks({ patientId }),
    );
  };

  const handleSubmitStartModalError = () => {
    const defaultMessage =
      'An unexpected issue occurred. Please refresh the page and try again. If the problem persists, contact support at support@stellar.health.';
    setStartModalErrorMessage(defaultMessage);
  };

  const handleSubmitStartModal = (dynamicFormFieldData) => {
    mutateStartOutreach(
      {
        patientId,
        patientPhoneNumber: dynamicFormFieldData?.patientPhoneNumber,
        providerId: dynamicFormFieldData?.providerSelection,
        providerOfficePhoneNumber: dynamicFormFieldData?.officePhoneNumber,
        actionId: selectedAction?.id,
      },
      {
        onSuccess: handleSubmitStartModalSuccess,
        onError: handleSubmitStartModalError,
      },
    );
  };

  const handleCloseStartModal = () => {
    setShowStartModal(false);
    setShowModal(true);
    setStartModalErrorMessage('');
  };

  const handleCloseStartModalConfirm = () => {
    setShowStartModalConfirm(false);
  };

  const handleOpenStopModal = () => {
    handleClose();
    setShowStopModal(true);
  };

  const handleCloseStopModal = () => {
    setShowStopModal(false);
    setShowStopModalConfirm(true);
  };

  const handleCancelStopModal = () => {
    setShowStopModal(false);
    setShowModal(true);
  };

  const handleCloseStopModalConfirm = () => {
    setShowStopModalConfirm(false);
  };

  return (
    <>
      <PatientBannerButton
        onClick={handleBannerButtonClick}
        pendoId="worklist-patient-detail-outreach-log"
        endIcon={
          outreachIsEnabled && campaignIsActive ? <ShActiveCalling /> : null
        }
      >
        Outreach log
      </PatientBannerButton>
      <BaseFormModal
        alert={
          <>
            <Typography
              variant="h4"
              sx={{ mt: 2 }}
              data-dd-action-name="Patient Name and Phone"
            >
              {patientName} {patientPhone}
            </Typography>
            {outreachIsEnabled &&
            outreachCampaign?.status === OUTREACH_CAMPAIGN_STATUS_ACTIVE ? (
              <OutreachInfoBanner />
            ) : null}
            <Box pt={1}>
              {isOutreachDataLoading || !outreachHistory ? null : (
                <>
                  <BaseLinkButton
                    type="button"
                    onClick={handleHistoryButtonClick}
                  >
                    {showHistory
                      ? 'Hide outreach history'
                      : 'View outreach history'}
                  </BaseLinkButton>
                  {showHistory ? (
                    <>
                      <Typography variant="bodysmall" fontWeight={900}>
                        Recent:
                      </Typography>
                      {outreachHistory?.map(
                        ({ id, note, createdAt, outcome, user }) => (
                          <HistoryItem
                            key={id}
                            note={note}
                            date={createdAt}
                            description={outcome}
                            userFullName={user}
                          />
                        ),
                      )}
                      <hr />
                    </>
                  ) : null}
                </>
              )}
            </Box>
          </>
        }
        fields={formFields}
        isLoading={isLoading}
        onCancel={handleClose}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitButtonPendoId="worklist-patient-detail-outreach-log-modal-save"
        open={showModal}
        submitButtonLabel="Confirm"
        title="Outreach log"
        headerVariant="h2"
        modalSx={outreachIsEnabled ? { width: '600px' } : { width: '408px' }}
        additionalProps={{
          campaign: outreachIsEnabled ? outreachCampaign : undefined,
          patientName,
          handleOpenStartModal: outreachIsEnabled
            ? handleOpenStartModal
            : undefined,
          handleOpenStopModal: outreachIsEnabled
            ? handleOpenStopModal
            : undefined,
        }}
      />
      {showStartModal && (
        <OutreachStartModal
          open={showStartModal}
          onCancel={handleCloseStartModal}
          onSubmit={handleSubmitStartModal}
          onClose={handleCloseStartModal}
          isSubmitting={isSubmittingStartOutreach}
          errorMessage={startModalErrorMessage}
        />
      )}
      {showStartModalConfirm && (
        <OutreachCampaignModalConfirm
          open={showStartModalConfirm}
          onCancel={handleCloseStartModalConfirm}
          patientName={patientName}
          header="Outreach started"
          description="Stellar will begin outreach to this patient. Calls will be made between 9:00 AM and 11:00 AM or between 2:00 PM and 4:00 PM local time."
          dataPendoId="outreach-campaign-start-cancel-button"
        />
      )}
      {showStopModal && (
        <OutreachCampaignStopModal
          open={showStopModal}
          onCancel={handleCancelStopModal}
          onClose={handleCloseStopModal}
          patientName={patientName}
          patientId={outreachCampaign?.patientId}
          refreshLogOutreach={refreshLogOutreach}
        />
      )}
      {showStopModalConfirm && (
        <OutreachCampaignModalConfirm
          open={showStopModalConfirm}
          onCancel={handleCloseStopModalConfirm}
          patientName={patientName}
          header="Outreach stopped"
          description="Stellar will discontinue outreach attempts to this patient on the next business day."
          dataPendoId="outreach-campaign-stop-cancel-button"
        />
      )}
    </>
  );
}

OutreachLogModal.propTypes = {
  patientId: PropTypes.number.isRequired,
  patientName: PropTypes.string,
  patientPhone: PropTypes.string,
};

OutreachLogModal.defaultProps = {
  patientName: null,
  patientPhone: null,
};
