import { first } from 'lodash';
import { useCallback } from 'react';

import {
  QueryParamValue,
  useQueryParameters,
} from '@/contexts/QueryParametersContext';

type Step = {
  stepId: string;
};

type Action = {
  id: number;
  v2Dto: {
    preferredPath: Array<Step>;
  };
};

type Patient = {
  id: number;
};

type Task = {
  id: number;
  action: Action;
  patient: Patient;
};

export default function useSelectTask() {
  const { mergeParameters } = useQueryParameters();
  const selectTask = useCallback(
    (task: Task, clearFirstTask = false) => {
      const { action, patient } = task;
      const preferredNextStep = first(action.v2Dto.preferredPath)!;
      const newParameters: Record<string, QueryParamValue> = {
        actionId: action.id,
        stepId: preferredNextStep.stepId,
        patientId: patient.id,
        taskId: task.id,
        isSidebarOpen: undefined,
      };
      if (clearFirstTask) {
        newParameters['openFirstTask'] = undefined;
      }
      mergeParameters(newParameters);
    },
    [mergeParameters],
  );

  return selectTask;
}
