import { Box, Button, Stack, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useCopyToClipboard, useMap } from 'react-use';
import { every, noop, values } from 'lodash';

// @ts-expect-error -- js file
import BaseModal from '@/components/BaseModal';
// @ts-expect-error -- js file
import BaseSpinner from '@/components/BaseSpinner';
// @ts-expect-error -- js file
import BaseCheckbox from '@/components/BaseCheckbox';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { ReactComponent as CopiedIcon } from '@/assets/icons/copied.svg';
import useCopyPasteData, {
  CopyPasteAction,
} from '@/components/CopyPasteModal/useCopyPasteData';
import useGetUser from '@/hooks/useGetUser';
import formatData from '@/components/CopyPasteModal/formatData';
import useDate from '@/hooks/useDate';
import useLogCopyPasteAudit from '@/components/CopyPasteModal/useLogCopyPasteAudit';

type Props = {
  patientId: number;
  open: boolean;
  appointmentId?: number | null;
  onClose: () => void;
  onCopy?: () => void;
};

type ActionToCopyCheckBoxProps = {
  action: CopyPasteAction;
  checked: boolean;
  onChange: (e: ChangeEvent) => void;
};

function ActionToCopyCheckBox({
  action,
  checked,
  onChange,
}: ActionToCopyCheckBoxProps) {
  return (
    <Stack gap={0.5}>
      <BaseCheckbox checked={checked} label={action.name} onChange={onChange} />
      {action.pastIcdCode != null && (
        <Typography variant="bodysmall" ml="22px">
          Documented as {action.pastIcdCode} by {action.documentedBy} on{' '}
          {action.documentedOn}
        </Typography>
      )}

      {action.possibleCodes && action.possibleCodes.length > 0 && (
        <Typography variant="bodysmall" ml="22px">
          Other possible codes: {action.possibleCodes.join(', ')}
        </Typography>
      )}
    </Stack>
  );
}

function CopyPasteModal({
  patientId,
  open,
  onClose,
  appointmentId = null,
  onCopy = noop,
}: Props) {
  const newDate = useDate();
  // Basically we need to memoize the date so the useEffect doesn't keep triggering, so we just stash it in state.
  const [dateOfService] = useState(newDate);
  const { patient, actions, isLoading, isPending } = useCopyPasteData({
    patientId,
    dateOfService,
    isEnabled: open,
  });

  const [copiedSimple, setCopiedSimple] = useState(false);
  const [copiedFormatted, setCopiedFormatted] = useState(false);

  const { data: user, isLoading: userIsLoading } = useGetUser({
    enabled: open,
  });

  const [selectedActionIds, { set }] = useMap<Record<number, boolean>>({});

  const { mutateAsync } = useLogCopyPasteAudit();

  const [_, copyToClipboard] = useCopyToClipboard();

  const selectAll = useCallback(
    () => actions?.forEach((x) => set(x.id, true)),
    [actions, set],
  );

  const selectNone = useCallback(
    () => actions?.forEach((x) => set(x.id, false)),
    [actions, set],
  );

  // default to all actions selected
  useEffect(() => {
    selectAll();
  }, [selectAll]);

  const showSpinner = isLoading || isPending || userIsLoading;

  const areAllSelected = every(values(selectedActionIds));

  const getSelectedActions = (): Array<CopyPasteAction> => {
    return actions?.filter((action) => selectedActionIds[action.id]) ?? [];
  };

  const copyWithFormatting = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    mutateAsync({
      patientId,
      appointmentId,
      actionIds: selectedActionIds,
      formatting: 'formatted',
      wasSelectAllChecked: areAllSelected,
    });

    setCopiedFormatted(true);

    onCopy();

    copyToClipboard(
      formatData(patient!, getSelectedActions(), user!, '\n\n', '\n'),
    );
  };

  const copySimple = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    mutateAsync({
      patientId,
      appointmentId,
      actionIds: selectedActionIds,
      formatting: 'simple',
      wasSelectAllChecked: areAllSelected,
    });

    setCopiedSimple(true);

    onCopy();

    copyToClipboard(
      formatData(patient!, getSelectedActions(), user!, ' :::::::: ', '; '),
    );
  };

  return (
    <BaseModal
      title={
        showSpinner
          ? ''
          : `Copy care gaps for ${patient!.firstName} ${patient!.lastName}`
      }
      open={open}
      onClose={onClose}
      sx={{
        minWidth: showSpinner ? 680 : 'fit-content',
        '& > .MuiDialogContent-root': { paddingTop: 0 },
      }}
    >
      {showSpinner ? (
        <Stack justifyContent="center" alignItems="center" flex="1 1">
          <BaseSpinner />
        </Stack>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            gap={1}
            sx={{
              justifyContent: 'space-between',
              borderBottom: '1px solid',
              borderBottomColor: 'border.base',
              paddingBottom: 1,
              paddingTop: 0.5,
            }}
          >
            <BaseCheckbox
              label="Select all gaps"
              checked={areAllSelected}
              onChange={areAllSelected ? selectNone : selectAll}
              data-pendo-id="copypaste-select-all"
              sx={{
                flexShrink: 0,
                alignItems: 'end',
                marginRight: '64px',
                paddingBottom: '9px',
              }}
            />
            <Box sx={{ textAlign: 'right' }}>
              <Button
                variant="tertiary"
                size="medium"
                disableRipple={false}
                sx={{
                  color: 'text.link',
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                }}
                onClick={copyWithFormatting}
                data-pendo-id="copypaste-copy-formatted"
                startIcon={
                  copiedFormatted ? (
                    <CopiedIcon width="14px" height="14px" />
                  ) : (
                    <CopyIcon width="14px" height="14px" />
                  )
                }
              >
                Copy gaps to clipboard (formatted)
              </Button>
              <Button
                variant="tertiary"
                size="medium"
                sx={{
                  color: 'text.link',
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                }}
                disableRipple={false}
                onClick={copySimple}
                data-pendo-id="copypaste-copy-simple"
                startIcon={
                  copiedSimple ? (
                    <CopiedIcon width="14px" height="14px" />
                  ) : (
                    <CopyIcon width="14px" height="14px" />
                  )
                }
              >
                Copy gaps to clipboard (simple)
              </Button>
            </Box>
          </Stack>
          <Stack gap={2} sx={{ paddingTop: 2 }}>
            {actions!.map((action) => (
              <ActionToCopyCheckBox
                key={action.id}
                action={action}
                checked={selectedActionIds[action.id] ?? false}
                onChange={() => set(action.id, !selectedActionIds[action.id])}
              />
            ))}
          </Stack>
        </Box>
      )}
    </BaseModal>
  );
}

export default CopyPasteModal;
