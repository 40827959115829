import { Box, Typography } from '@mui/material';

type BasicBadgeProps = {
  text: string;
};

function BasicBadge({ text }: BasicBadgeProps) {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        px: 1.5,
        py: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '60px',
        backgroundColor: 'text.link',
        height: '22px',
        whiteSpace: 'nowrap',
      }}
    >
      <Typography variant="bodysmallbold" color="text.primaryInverse">
        {text}
      </Typography>
    </Box>
  );
}

export default BasicBadge;
