import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as CheckCircleIcon } from '@/assets/icons/checkCircle.svg';

function OutreachCampaignModalConfirm({
  open,
  onCancel,
  patientName,
  header,
  description,
  dataPendoId,
}) {
  const theme = useTheme();
  return (
    <Modal open={open}>
      <StyledPaper>
        <Box
          sx={{
            p: 3,
            borderBottom: '1px solid',
            borderBottomColor: 'stroke.light',
          }}
        >
          <Stack direction="row" alignItems="top" gap={1}>
            <Box>
              <CheckCircleIcon />
            </Box>
            <Box>
              <Typography variant="h2">{header}</Typography>

              <Typography>{patientName}</Typography>
              <StyledTypography>{description}</StyledTypography>
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            bgcolor: 'background.secondary',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme.spacing(2, 3),
          }}
        >
          <Button
            data-pendo-id={dataPendoId}
            onClick={onCancel}
            variant="outlined"
            size="large"
          >
            Close
          </Button>
        </Box>
      </StyledPaper>
    </Modal>
  );
}

OutreachCampaignModalConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  patientName: PropTypes.string,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dataPendoId: PropTypes.string.isRequired,
};

OutreachCampaignModalConfirm.defaultProps = {
  patientName: '',
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: `${4 * theme.shape.borderRadius}px`,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  position: 'absolute',
  width: '638px',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export default OutreachCampaignModalConfirm;
