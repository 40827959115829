import PropTypes from 'prop-types';

import { groupClaimShape } from '@/pages/PrintSummary/utils/printPropTypes';
import {
  VisitGroupDate,
  getVisitKey,
} from '@/pages/PrintSummary/components/InpatientAndEdVisitsTable';
import {
  StyledHistoryCell,
  StyledNoData,
  StyledPatientHistoryHeaderRow,
  StyledPatientHistoryRow,
  StyledPatientHistoryTable,
  StyledPatientRowText,
  StyledPatientTabHeader,
  StyledTotalSummaryRow,
} from '@/pages/PatientSummary/components/VisitsDxHistory/styles';
import { INPATIENT_ED_VISITS_FLEX_VALUES } from '@/pages/PatientSummary/components/VisitsDxHistory/constants';
import LoadingModalTable from '@/pages/PatientSummary/components/VisitsDxHistory/LoadingModalTable';

function InpatientAndEdVisitRow({ visit, num }) {
  const {
    isEd,
    likelyAvoidable: visitLikelyAvoidable,
    facilityName,
    mostRecentDiagnoses,
  } = visit;
  const visitType = isEd ? 'ED' : 'Inpatient';
  const likelyAvoidable = (() => {
    if (visitLikelyAvoidable === true) {
      return 'Yes';
    }
    if (visitLikelyAvoidable === false) return 'No';
    return '--';
  })();
  const facility = facilityName;
  return (
    <StyledPatientHistoryRow num={num}>
      <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[0]}>
        <VisitGroupDate
          fromDate={visit.groupFromDate}
          thruDate={visit.groupThruDate}
        />
      </StyledHistoryCell>
      <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[1]}>
        {visitType}
      </StyledHistoryCell>
      <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[2]}>
        {likelyAvoidable}
      </StyledHistoryCell>
      <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[3]}>
        {facility}
      </StyledHistoryCell>
      <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[4]}>
        {mostRecentDiagnoses?.map((dx, i) => (
          <StyledPatientRowText key={`${dx.diagnosisCode}-${i + 1}`}>
            {dx.diagnosisCode} - {dx.description}
          </StyledPatientRowText>
        ))}
      </StyledHistoryCell>
    </StyledPatientHistoryRow>
  );
}

function InpatientAndEdVisitsSummary({ visits, isLoading }) {
  return (
    <StyledPatientHistoryTable>
      <StyledTotalSummaryRow>
        {visits?.length || 0} in last 12 months
      </StyledTotalSummaryRow>
      <StyledPatientHistoryHeaderRow>
        <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[0]}>
          <StyledPatientTabHeader>Date</StyledPatientTabHeader>
        </StyledHistoryCell>
        <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[1]}>
          <StyledPatientTabHeader>Type</StyledPatientTabHeader>
        </StyledHistoryCell>
        <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[2]}>
          <StyledPatientTabHeader>Likely avoidable?</StyledPatientTabHeader>
        </StyledHistoryCell>
        <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[3]}>
          <StyledPatientTabHeader>Facility</StyledPatientTabHeader>
        </StyledHistoryCell>
        <StyledHistoryCell flex={INPATIENT_ED_VISITS_FLEX_VALUES[4]}>
          <StyledPatientTabHeader>Diagnoses</StyledPatientTabHeader>
        </StyledHistoryCell>
      </StyledPatientHistoryHeaderRow>

      {isLoading ? (
        <StyledPatientHistoryRow num={2}>
          <LoadingModalTable flexDims={INPATIENT_ED_VISITS_FLEX_VALUES} />
        </StyledPatientHistoryRow>
      ) : null}
      {!isLoading && visits?.length ? (
        visits.map((visit, i) => (
          <InpatientAndEdVisitRow
            key={getVisitKey(visit)}
            visit={visit}
            num={i}
          />
        ))
      ) : (
        <StyledPatientHistoryRow num={2} p={1}>
          <StyledNoData>No inpatient / ED history data available.</StyledNoData>
        </StyledPatientHistoryRow>
      )}
    </StyledPatientHistoryTable>
  );
}

InpatientAndEdVisitsSummary.propTypes = {
  visits: PropTypes.arrayOf(groupClaimShape),
  isLoading: PropTypes.bool,
};

InpatientAndEdVisitsSummary.defaultProps = {
  isLoading: false,
  visits: null,
};

InpatientAndEdVisitRow.propTypes = {
  visit: groupClaimShape,
  num: PropTypes.number,
};

InpatientAndEdVisitRow.defaultProps = {
  visit: null,
  num: 1,
};

export default InpatientAndEdVisitsSummary;
