import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { NavbarConfig } from '../types';

async function fetchNotifications(): Promise<NavbarConfig> {
  const response = await axios.get('/api/navbar_notifications');
  return response.data as NavbarConfig;
}

export default function useGetNavbarNotifications({
  enabled,
  placeholderData,
}: {
  enabled: boolean;
  placeholderData: NavbarConfig;
}) {
  return useQuery({
    queryFn: fetchNotifications,
    queryKey: ['navbarNotifications'],
    enabled,
    placeholderData,
  });
}
