import Box from '@mui/material/Box';
import styled from 'styled-components';

const HeaderBox = styled(Box)`
  background-color: ${(p) => p.theme.palette.background.base};
  border-bottom: ${(props) => props.theme.spacing(0.2)} solid
    ${(props) => props.theme.palette.border.base};
  height: ${(props) => props.theme.spacing(10)};
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};
  padding-left: ${(props) => props.theme.spacing(4)};
`;

export default HeaderBox;
