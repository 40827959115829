import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import queryKeyFactory from '../queryKeyFactory';

function transformLogOutreach(logOutreach) {
  return {
    id: logOutreach.id,
    note: logOutreach.note?.text,
    createdAt: logOutreach.created_at,
    user: logOutreach.user_full_name,
    outcome: logOutreach.outcome[1],
  };
}

function transformLogCampaign(campaign) {
  return {
    id: campaign?.id,
    patientId: campaign?.patient_id,
    status: campaign?.status,
    providerId: campaign?.provider_id,
    providerOfficePhoneNumber: campaign?.provider_office_phone_number,
    actionId: campaign?.action_id,
  };
}

const transformResponse = (data = {}) => ({
  outreaches: data.outreaches
    ? data.outreaches.map(transformLogOutreach)
    : undefined,
  campaign:
    data?.campaign !== undefined
      ? transformLogCampaign(data.campaign)
      : undefined,
});

async function fetchLogOutreach({ patientId }) {
  const response = await axios.get(`/api/patient/${patientId}/outreaches`);

  return response.data;
}

export default function useGetLogOutreach({ patientId }) {
  const params = { patientId };

  return useQuery(
    queryKeyFactory.logOutreach(params),
    () => fetchLogOutreach(params),
    {
      select: transformResponse,
    },
  );
}
