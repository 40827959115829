import { format, isValid, parse, parseJSON } from 'date-fns';
import { Typography } from '@mui/material';

import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
} from '@/pages/PatientSummary/utils/constants';
import PrintToolBarWithIncludeHistoryCheckbox from '@/pages/PrintSummary/components/PrintToolBarWithIncludeHistoryCheckbox';
import PrintToolBar from '@/pages/PrintSummary/components/PrintToolBar';

export function diagnosisSubheadHelper(condition, displayText, preposition) {
  return condition ? (
    <>
      {' '}
      {preposition}{' '}
      <Typography fontWeight="bold" component="span">
        {displayText}
      </Typography>
    </>
  ) : null;
}

// This receives a string of one of the follow forms:

// Previously Coded, ICD10: E1165: Type 2 diabetes mellitus with hyperglycemia
// Exudative age-related macular degeneration
// Rx-Pentoxifylline Oral (Trental)

// For the first form, we want to bold the ICD code by splitting the string on the code
// and returning it wrapped in a bold element
// For the second and third forms, we just want to bold the whole thing.
export function smcSubheadHelper(subhead) {
  if (subhead.code) {
    const parts = subhead.suspectedDetail.split(subhead.code);
    if (parts.length === 2) {
      return (
        <>
          {parts[0]}
          <Typography fontWeight="bold" component="span">
            {subhead.code}
          </Typography>
          {parts[1]}
        </>
      );
    }
  }
  return (
    <Typography fontWeight="bold" component="span">
      {subhead.suspectedDetail}
    </Typography>
  );
}

export function maybeFormatDate(date, formatStr = DISPLAY_DATE_FORMAT) {
  // Attempts to parse and format the date according to formatStr
  // If it doesn't succeed, returns the input as-is
  const parsed = parse(date, 'yyyy-MM-dd', new Date());
  return isValid(parsed) ? format(parsed, formatStr) : date;
}

export function maybeFormatDateTime(
  datetime,
  formatStr = DISPLAY_DATE_TIME_FORMAT,
) {
  // Attempts to parse & format a JSON datetime according to formatStr
  // If it doesn't succeed, returns the input as-is
  const parsed = parseJSON(datetime);
  return isValid(parsed) ? format(parsed, formatStr) : datetime;
}

export function flexFormatDateTime(str) {
  if (maybeFormatDate(str) === str) {
    if (maybeFormatDateTime(str) === str) {
      // if formatting as a date and a datetime both fail, just return as-is
      return str;
    }
    return maybeFormatDateTime(str);
  }
  return maybeFormatDate(str);
}

export function extractActionSubtypes(actions = []) {
  const diagnosisActions = actions.filter(
    (action) => action.subtype === 'recapture',
  );
  const suspectedActions = actions.filter(
    (action) => action.subtype === 'suggestion',
  );
  const careGapActions = actions.filter(
    (action) =>
      action.subtype !== 'recapture' && action.subtype !== 'suggestion',
  );
  return { diagnosisActions, suspectedActions, careGapActions };
}

// Feature Logic -----------------------------------------------
const SOURCE_PATIENT_SUMMARY = 'patient_summary';
export { SOURCE_PATIENT_SUMMARY };

export function shouldShowVisitAndDxHistoryTable(
  isIncludeVisitAndDxHistory,
  source,
  ppvCadence,
  isMedicalHistoryEnabled,
) {
  const toggleTable =
    (isIncludeVisitAndDxHistory ||
      (source !== SOURCE_PATIENT_SUMMARY && !!ppvCadence)) &&
    isMedicalHistoryEnabled;
  return toggleTable;
}

export function determineToolbarComponent(
  enablePriorityPatientVisitsClaimsData,
  isIncludeVisitAndDxHistory,
  source,
) {
  if (
    enablePriorityPatientVisitsClaimsData &&
    isIncludeVisitAndDxHistory &&
    source === SOURCE_PATIENT_SUMMARY
  ) {
    return PrintToolBarWithIncludeHistoryCheckbox;
  }
  return PrintToolBar;
}
