import { Typography, styled } from '@mui/material';

import { goalShape } from './shapes';

export default function SubtypePrioritization({ goal }) {
  return (
    <SubtypePrioritizationRoot>
      <Typography variant="bodysmall">
        Prioritize:{' '}
        <Typography variant="bodysmallbold" component="span">
          {goal.subtypeFocusLabel}
        </Typography>
      </Typography>
    </SubtypePrioritizationRoot>
  );
}

SubtypePrioritization.propTypes = {
  goal: goalShape.isRequired,
};

const SubtypePrioritizationRoot = styled('div')`
  background-color: ${(p) => p.theme.palette.background.primaryLightest};
  text-align: center;
  display: inline-block;
  padding: ${(p) => p.theme.spacing(0, 4)};
  white-space: nowrap;
`;
