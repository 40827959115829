import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { Input, Stack } from '@mui/material';
import useId from '@mui/utils/useId';

import FormErrorMessage from '@/components/FormErrorMessage';

import DynamicFormLabel from '../DynamicFormLabel';

function DynamicFormPhoneNumberInput({ field }) {
  const { label, name, required } = field;
  const fieldId = useId();

  const controller = useController({
    name,
    defaultValue: '',
    rules: {
      required: required ? 'This field is required' : undefined,
      pattern: {
        value: /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        message: 'Invalid phone number format',
      },
    },
  });

  return (
    <Stack marginTop={(theme) => theme.spacing(2)}>
      <DynamicFormLabel htmlFor={fieldId}>{label}</DynamicFormLabel>
      {/* For usability reasons, don't use type="number" on the input */}
      {/* https://mui.com/material-ui/react-text-field/#type-quot-number-quot */}
      <Input
        id={fieldId}
        inputProps={{ inputMode: 'numeric' }}
        disableUnderline
        sx={(theme) => ({
          boxShadow: 'None',
          width: 130,
          border: `1px solid ${theme.palette.border.input}`,
          '&:hover': {
            border: `2px solid ${theme.palette.primary.main}`,
            boxShadow: `0 0 0 3.2px ${theme.palette.focus.shadow}`,
          },
        })}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controller.field}
      />
      <FormErrorMessage errors={controller.formState.errors} name={name} />
    </Stack>
  );
}

DynamicFormPhoneNumberInput.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
};

export default DynamicFormPhoneNumberInput;
