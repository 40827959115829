import PropTypes from 'prop-types';

import { maybeFormatDate } from '@/pages/PrintSummary/utils/printUtils';
import { officeVisitShape } from '@/pages/PrintSummary/utils/printPropTypes';
import {
  StyledHistoryCell,
  StyledNoData,
  StyledPatientHistoryHeaderRow,
  StyledPatientHistoryRow,
  StyledPatientHistoryTable,
  StyledPatientRowText,
  StyledPatientTabHeader,
  StyledTotalSummaryRow,
} from '@/pages/PatientSummary/components/VisitsDxHistory/styles';
import { OFFICE_VISITS_FLEX_VALUES } from '@/pages/PatientSummary/components/VisitsDxHistory/constants';
import LoadingModalTable from '@/pages/PatientSummary/components/VisitsDxHistory/LoadingModalTable';

function OfficeVisitRow({ visit, num }) {
  const mostRecentOfficeVisitCount = 1;
  return (
    <StyledPatientHistoryRow num={num}>
      <StyledHistoryCell flex={OFFICE_VISITS_FLEX_VALUES[0]}>
        <StyledPatientRowText>
          {maybeFormatDate(visit.fromDate)}
        </StyledPatientRowText>
      </StyledHistoryCell>
      <StyledHistoryCell flex={OFFICE_VISITS_FLEX_VALUES[1]}>
        <StyledPatientRowText>{visit.provider.name} </StyledPatientRowText>
      </StyledHistoryCell>
      <StyledHistoryCell flex={OFFICE_VISITS_FLEX_VALUES[2]}>
        <StyledPatientRowText>{visit.providerType} </StyledPatientRowText>
      </StyledHistoryCell>
      <StyledHistoryCell flex={OFFICE_VISITS_FLEX_VALUES[3]}>
        <StyledPatientRowText>
          {mostRecentOfficeVisitCount + visit.otherVisitsToThisProvider.length}{' '}
        </StyledPatientRowText>
      </StyledHistoryCell>
      <StyledHistoryCell flex={OFFICE_VISITS_FLEX_VALUES[4]}>
        {visit.mostRecentDiagnoses?.map((dx, i) => (
          <StyledPatientRowText key={`${dx.diagnosisCode}-${i + 1}`}>
            {dx.diagnosisCode} - {dx.description}
          </StyledPatientRowText>
        ))}
      </StyledHistoryCell>
    </StyledPatientHistoryRow>
  );
}

function OfficeVisitsSummary({ visits, isLoading }) {
  return (
    <StyledPatientHistoryTable>
      <StyledTotalSummaryRow>
        {visits?.length || 0} in last 12 months
      </StyledTotalSummaryRow>
      <StyledPatientHistoryHeaderRow>
        <StyledPatientTabHeader flex={OFFICE_VISITS_FLEX_VALUES[0]}>
          Last visit date
        </StyledPatientTabHeader>
        <StyledPatientTabHeader flex={OFFICE_VISITS_FLEX_VALUES[1]}>
          Provider
        </StyledPatientTabHeader>
        <StyledPatientTabHeader flex={OFFICE_VISITS_FLEX_VALUES[2]}>
          Type
        </StyledPatientTabHeader>
        <StyledPatientTabHeader flex={OFFICE_VISITS_FLEX_VALUES[3]}>
          # of Visits
        </StyledPatientTabHeader>
        <StyledPatientTabHeader flex={OFFICE_VISITS_FLEX_VALUES[4]}>
          Diagnoses
        </StyledPatientTabHeader>
      </StyledPatientHistoryHeaderRow>

      {isLoading ? (
        <StyledPatientHistoryRow num={2}>
          <LoadingModalTable flexDims={OFFICE_VISITS_FLEX_VALUES} />
        </StyledPatientHistoryRow>
      ) : null}
      {!isLoading && visits?.length ? (
        visits?.map((visit, i) => (
          <OfficeVisitRow
            num={i}
            visit={visit}
            key={`${visit.mostRecentClaimId}-${i + 1}`}
          />
        ))
      ) : (
        <StyledPatientHistoryRow num={2} p={1}>
          <StyledNoData>No office visit data available.</StyledNoData>
        </StyledPatientHistoryRow>
      )}
    </StyledPatientHistoryTable>
  );
}

OfficeVisitsSummary.propTypes = {
  visits: PropTypes.arrayOf(officeVisitShape),
  isLoading: PropTypes.bool,
};

OfficeVisitsSummary.defaultProps = {
  isLoading: false,
  visits: null,
};

OfficeVisitRow.propTypes = {
  visit: officeVisitShape,
  num: PropTypes.number,
};

OfficeVisitRow.defaultProps = {
  visit: null,
  num: 1,
};

export default OfficeVisitsSummary;
