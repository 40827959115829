import { AppBar, Button, Toolbar, Typography } from '@mui/material';

import BaseCheckbox from '@/components/BaseCheckbox';
import useIncludeVisitAndDxHistory from '@/pages/PrintSummary/contexts/useIncludeVisitAndDxHistory';

import { ButtonLabel, PrintIcon } from '../utils/printStyles';

function PrintToolBarWithIncludeHistoryCheckbox() {
  const { isIncludeVisitAndDxHistory, toggle } = useIncludeVisitAndDxHistory();
  return (
    <AppBar
      position="sticky"
      sx={{
        borderRadius: 0,
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 2,
        displayPrint: 'none',
      }}
    >
      <Toolbar sx={{ width: '216mm', mx: 'auto' }}>
        <Typography variant="h2" sx={{ mr: '1rem' }}>
          Print Preview
        </Typography>
        <BaseCheckbox
          checked={isIncludeVisitAndDxHistory}
          label={
            <Typography variant="bodysmall">
              Include visit and Dx history
            </Typography>
          }
          onChange={() => toggle()}
          key="include-visit-dx-history-check"
          data-pendo-id="print_patient_history_checkbox"
        />
        <Button
          sx={{ ml: 'auto', mr: '1rem' }}
          variant="text"
          onClick={() => {
            // This is a hack to allow the script to close its own tab
            window.open('about:blank', '_self');
            window.close();
          }}
        >
          <ButtonLabel>Cancel</ButtonLabel>
        </Button>
        <Button onClick={() => window.print()} sx={{ lineHeight: 2, px: 3.5 }}>
          <PrintIcon />
          <ButtonLabel>Print</ButtonLabel>
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default PrintToolBarWithIncludeHistoryCheckbox;
