import { isBefore, isValid, parseISO } from 'date-fns';

import useTimestampCookie from '@/hooks/useTimestampCookie';

const COOKIE_NAME = 'lastViewedTimestamp';

function parseDate(input: string) {
  const output = parseISO(input);
  return isValid(output) ? output : null;
}

type CustomMessage = {
  updated: string;
  startsAt: string;
};

/**
 * Hook that returns a value and a callback which updates the latest viewed timestamp.
 */
export default function useHasNewMessages(
  customMessages: Array<CustomMessage>,
): [boolean, () => void] {
  const [lastViewedTimestamp, updateTimestamp] =
    useTimestampCookie(COOKIE_NAME);

  if (!customMessages?.length) return [false, updateTimestamp];

  const hasNewMessages = Boolean(
    !lastViewedTimestamp ||
      customMessages.some((customMessage) => {
        const updated = parseDate(customMessage.updated)!;
        const startsAt = parseDate(customMessage.startsAt);
        return (
          // The message was created or updated after last viewed.
          isBefore(lastViewedTimestamp, updated) ||
          // The message started after last viewed.
          Boolean(startsAt && isBefore(lastViewedTimestamp, startsAt))
        );
      }),
  );

  return [hasNewMessages, updateTimestamp];
}
