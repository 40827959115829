import type { User } from '@/hooks/useGetUser';

import type { CopyPasteAction, Patient } from './useCopyPasteData';

function formatPatientData(patient: Patient): string {
  const firstInitial = patient.firstName[0];
  return `${firstInitial}. ${patient.lastName} DOB ${patient.dateOfBirth}`;
}

function formatAction(action: CopyPasteAction, separator = '\n') {
  const parts = [action.name];
  if (action.pastIcdCode) {
    // action.documentedOn is already in the right format, so we don't need to parse/format it.
    parts.push(
      `Documented as ${action.pastIcdCode} by ${action.documentedBy} on ${action.documentedOn}`,
    );
  }

  if (action.possibleCodes.length > 0) {
    parts.push(`Other possible codes: ${action.possibleCodes.join(', ')}`);
  }

  return parts.join(separator);
}

function formatUser(user: User): string {
  const firstInitial = user.firstName[0];
  return `${firstInitial}. ${user.lastName}`;
}

function formatData(
  patient: Patient,
  actions: Array<CopyPasteAction>,
  user: User,
  lineSeparator = '\n\n',
  partSeparator = '\n',
) {
  const formattedPatient = formatPatientData(patient);

  const formattedUser = formatUser(user);

  const firstLine = `From Stellar Health | ${formattedPatient} | added by ${formattedUser}`;

  const formattedActions = actions.map((action) =>
    formatAction(action, partSeparator),
  );

  return [firstLine, ...formattedActions].join(lineSeparator);
}

export default formatData;
