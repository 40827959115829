import { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useEffectOnce } from 'react-use';

import DxHistorySummary from '@/pages/PatientSummary/components/VisitsDxHistory/DxHistorySummary';
import InpatientAndEdVisitsSummary from '@/pages/PatientSummary/components/VisitsDxHistory/InpatientAndEdVisitsSummary';
import OfficeVisitsSummary from '@/pages/PatientSummary/components/VisitsDxHistory/OfficeVisitsSummary';
import getPatientIdFromUrl from '@/pages/PatientSummary/utils/getPatientIdFromUrl';
import useGetVisitsAndDxHistory from '@/pages/PrintSummary/hooks/useGetVisitsAndDxHistory';
import {
  FilterTabsRoot,
  StyledNoteCell,
  StyledTabLink,
} from '@/pages/PatientSummary/components/VisitsDxHistory/styles';

const DIAGNOSIS_HISTORY = 'Diagnosis history';
const INPATIENT_ED_VISITS = 'Inpatient and ED visits';
const OFFICE_VISITS = 'Office visits';
const TABS_TO_SHOW = [DIAGNOSIS_HISTORY, INPATIENT_ED_VISITS, OFFICE_VISITS];

function VisitsDxHistoryTabularTable({ activeTab, patientData, patientId }) {
  const [patientHistoryData, setPatientHistoryData] = useState(patientData);
  const [currentActiveTab, setCurrentActiveTab] = useState(activeTab);

  const currentPatientId =
    patientId || getPatientIdFromUrl(window.location.pathname);

  const {
    data: { [currentPatientId]: responseData },
    isLoading,
  } = useGetVisitsAndDxHistory({
    patientIds: [currentPatientId],
  });
  useEffectOnce(() => {
    if (!patientHistoryData && !patientData && responseData) {
      setPatientHistoryData(responseData);
    }
  }, [responseData, patientData, patientHistoryData]);

  const evidence = patientHistoryData?.evidence ?? {};
  const { officeVisits, inpatientErVisits, dxHistory } = evidence;

  const displayActiveTabData = () => {
    switch (currentActiveTab) {
      case DIAGNOSIS_HISTORY:
        return <DxHistorySummary diagnoses={dxHistory} isLoading={isLoading} />;
      case INPATIENT_ED_VISITS:
        return (
          <InpatientAndEdVisitsSummary
            visits={inpatientErVisits}
            isLoading={isLoading}
          />
        );
      case OFFICE_VISITS:
        return (
          <OfficeVisitsSummary visits={officeVisits} isLoading={isLoading} />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <StyledNoteCell>
        <Typography variant="bodyLarge" display="block">
          NOTE: this list is derived from insurance claims data and may not be
          complete.
        </Typography>
      </StyledNoteCell>
      <FilterTabsRoot>
        {TABS_TO_SHOW.map((name) => (
          <StyledTabLink
            onClick={() => setCurrentActiveTab(name)}
            active={(currentActiveTab === name).toString()}
            key={name}
          >
            {name}
          </StyledTabLink>
        ))}
      </FilterTabsRoot>
      {displayActiveTabData()}
    </>
  );
}

VisitsDxHistoryTabularTable.propTypes = {
  activeTab: PropTypes.string,
  patientData: PropTypes.object,
  patientId: PropTypes.number,
};

VisitsDxHistoryTabularTable.defaultProps = {
  activeTab: DIAGNOSIS_HISTORY,
  patientData: null,
  patientId: null,
};

export default VisitsDxHistoryTabularTable;
