import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { ReactComponent as startCallIcon } from '@/assets/icons/startCall.svg';
import { ReactComponent as stopCallIcon } from '@/assets/icons/stopCall.svg';
import { OUTREACH_CAMPAIGN_STATUS_ACTIVE } from '@/pages/TaskBasedWorkflow/constants';

function OutreachCampaignControlButton({
  campaign,
  handleOpenStartModal,
  handleOpenStopModal,
}) {
  const isActiveCampaign = campaign?.status === OUTREACH_CAMPAIGN_STATUS_ACTIVE;

  const handleClick = () => {
    if (isActiveCampaign) {
      handleOpenStopModal();
    } else {
      handleOpenStartModal();
    }
  };

  const ButtonIcon = isActiveCampaign ? stopCallIcon : startCallIcon;

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      startIcon={<ButtonIcon />}
      data-pendo-id="outreach-campaign-control-button"
    >
      {isActiveCampaign
        ? 'Stop automated outreach'
        : 'Start automated outreach'}
    </Button>
  );
}

OutreachCampaignControlButton.propTypes = {
  campaign: PropTypes.shape({
    status: PropTypes.string,
  }),
  handleOpenStartModal: PropTypes.func.isRequired,
  handleOpenStopModal: PropTypes.func.isRequired,
};

OutreachCampaignControlButton.defaultProps = {
  campaign: null,
};

export default OutreachCampaignControlButton;
