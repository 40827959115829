import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import getActionExecutionRequirements from '@/pages/PatientSummary/utils/getActionExecutionRequirements';
import transformFrontendUserInputTask from '@/utils/transformFrontendUserInputTask';
import enhanceStepExecutionRequirements from '@/utils/enhanceStepExecutionRequirements';
import { useQueryParameters } from '@/contexts/QueryParametersContext';

import queryKeyFactory from '../queryKeyFactory';
import getStepExecutionFieldPendoId from '../utils/getStepExecutionFieldPendoId';
import { usePage } from '../contexts/PageContext';

function stepExecutionRequirementFilter(requirement) {
  return requirement.visible_to_user !== false;
}

/**
 * Function to generate a modified execution requirements to be used by the TBW page.
 */
function generateModifiedExecutionRequirements(action, step) {
  // label fields are excluded from old tbw pages for now, but going forward labels will display on new pages for
  // feature/ui parity with the patient summary forms. After review, labels will be added back into the old pages.
  if (step.executionRequirements) {
    return step.executionRequirements
      .map((req) => enhanceStepExecutionRequirements(req, step))
      .filter(stepExecutionRequirementFilter)
      .map((field) => {
        return {
          ...field,
          dataPendoId: getStepExecutionFieldPendoId({
            templateStep: step.templateStep,
            requirementName: field.name,
          }),
          contextualInfo: action.v2Dto.contextualInfo.original,
          label: field.label,
        };
      });
  }

  return action
    ? getActionExecutionRequirements(action.subtype, step.stepId)
    : null;
}

export function transformResponse(data) {
  return {
    results: data.results.map((task) => {
      const transformedTask = transformFrontendUserInputTask(task);
      transformedTask.action.v2Dto.availableNextSteps.forEach((step) => {
        // eslint-disable-next-line no-param-reassign
        step.modifiedExecutionRequirements =
          generateModifiedExecutionRequirements(transformedTask.action, step);
      });
      return transformedTask;
    }),
  };
}

async function fetchPatientTasks({
  page,
  patientId,
  includeHidden,
  allSubtypes,
}) {
  const response = await axios.get(
    `/api/tbw/${page}/patients/${patientId}/tasks`,
    {
      params: {
        include_hidden: includeHidden,
        all_subtypes: allSubtypes,
        page_size: 200, // Some arbitrarily large number to get all patient tasks
      },
    },
  );
  return response.data;
}

export default function useGetPatientTasks({
  patientId,
  includeHidden = undefined,
  onSuccess = undefined,
}) {
  const page = usePage();
  const {
    parameters: { allSubtypes },
  } = useQueryParameters();
  const params = { patientId, page, includeHidden, allSubtypes };
  return useQuery(
    queryKeyFactory.patientTasks(params),
    () => fetchPatientTasks(params),
    {
      enabled: Boolean(patientId),
      select: transformResponse,
      onSuccess,
    },
  );
}
