import { TableFooter, TablePagination, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

export default function TablePaginationFooter({
  numRows,
  rowsPerPage,
  page,
  handlePageChange,
  rowsPerPageOptions,
  sx,
}) {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={numRows}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handlePageChange}
          sx={sx}
        />
      </TableRow>
    </TableFooter>
  );
}

TablePaginationFooter.propTypes = {
  numRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  sx: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

TablePaginationFooter.defaultProps = {
  sx: undefined,
};
