import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { last } from 'lodash';
import { useState } from 'react';

import { ReactComponent as RemovedIcon } from '../patientDetailView/actionCard/assets/removed.svg';
import NotesAndRecentActivity from '../patientDetailView/actionCard/NotesAndRecentActivity';
import { userInputTaskShape } from '../../../../utils/shapes';

import {
  ActionCardSection,
  CompletedCardRoot,
  StyledCheckTrueIcon,
  getCompletionText,
} from './ActionCard';

export default function CompletedActionCard({
  userInputTask,
  sx,
  isCompleted,
  isRemoved,
}) {
  const isActionCompleteWithNextSteps =
    isCompleted && userInputTask.action.v2Dto.availableNextSteps.length > 0;
  const updatedActionV2 = userInputTask.action.v2Dto;
  const lastExecutedStep = last(updatedActionV2.stepHistories);

  const [notesAndRecentActivityExpanded, setNotesAndRecentActivityExpanded] =
    useState(false);
  const notesAndRecentActivity = (
    <NotesAndRecentActivity
      expanded={notesAndRecentActivityExpanded}
      setNotesAndRecentActivityExpanded={setNotesAndRecentActivityExpanded}
      flagHistories={userInputTask.flagHistories}
      stepHistories={userInputTask.action?.v2Dto.stepHistories}
      hideForNowHistories={userInputTask.hideForNowHistories}
      legacyNotes={userInputTask.legacyNotes}
      externalCareGaps={
        userInputTask.action?.v2Dto?.contextualInfo
          ?.externalCareGapRecentActivity
      }
      sx={{ mt: 1, '&.Mui-expanded:first-of-type': { mt: 1 } }}
    />
  );

  // svusPostPromo is null if there are no promos. Use svusPrePromo as a fallback.
  if (lastExecutedStep) {
    const svusEarned =
      lastExecutedStep.svusPostPromo || lastExecutedStep.svusPrePromo;

    return (
      <CompletedCardRoot sx={[{ bgcolor: 'background.transparent' }, sx]}>
        <ActionCardSection>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isRemoved ? <RemovedIcon /> : <StyledCheckTrueIcon />}

            <Typography
              color={isRemoved ? undefined : 'status.successForeground'}
              ml={1}
              variant="bodybold"
            >
              {updatedActionV2.description}
            </Typography>

            <Typography
              variant="bodybold"
              sx={{
                color: 'status.successForeground',
                textTransform: 'capitalize',
                ml: 'auto',
                pl: 3,
                whiteSpace: 'nowrap',
              }}
            >
              {svusEarned}{' '}
              <Typography variant="bodysmall" component="span" fontWeight={400}>
                SVU Earned
              </Typography>
            </Typography>
          </Box>

          <Typography mt={1.5} ml="40px">
            {getCompletionText(
              isRemoved,
              lastExecutedStep,
              isActionCompleteWithNextSteps,
            )}
          </Typography>

          {notesAndRecentActivity}
        </ActionCardSection>
      </CompletedCardRoot>
    );
  }
  return null;
}

CompletedActionCard.propTypes = {
  userInputTask: userInputTaskShape.isRequired,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isCompleted: PropTypes.bool,
  isRemoved: PropTypes.bool,
};

CompletedActionCard.defaultProps = {
  sx: undefined,
  isCompleted: false,
  isRemoved: false,
};
