/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Popper,
  SxProps,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { Fragment } from 'react';

// The duration of transitions in milliseconds;
const TRANSITION_TIMEOUT = 150;

type DropdownItem = {
  href: string;
  label: string;
  openInNewTab: boolean;
};

type DropdownSection = {
  header: string;
  items: Array<DropdownItem>;
};

const StyledListItemButton: typeof ListItemButton = styled(ListItemButton)`
  color: ${(p) => p.theme.palette.text.primary};
  transition: color ${TRANSITION_TIMEOUT};

  &:hover {
    color: ${(p) => p.theme.palette.text.link};
    background-color: ${(p) => p.theme.palette.background.primaryLightest};
  }
`;

function DropdownMenu({
  dropdownSections,
}: {
  dropdownSections: Array<DropdownSection>;
}) {
  return (
    <>
      {dropdownSections.map(({ header, items }, index) => (
        <Fragment key={index}>
          {index > 0 ? <Divider /> : null}

          <List
            sx={{ py: 1 }}
            subheader={
              header ? (
                <ListSubheader disableSticky>
                  <Typography
                    variant="bodymediumbold"
                    color="text.primary"
                    py={1}
                  >
                    {header}
                  </Typography>
                </ListSubheader>
              ) : undefined
            }
          >
            {items.map(({ href, label, openInNewTab }) => (
              <ListItem key={label} disablePadding>
                <StyledListItemButton
                  component="a"
                  href={href}
                  target={openInNewTab ? '_blank' : undefined}
                  rel={openInNewTab ? 'noopener noreferrer' : undefined}
                >
                  {label}
                </StyledListItemButton>
              </ListItem>
            ))}
          </List>
        </Fragment>
      ))}
    </>
  );
}

const UserDropdownRoot = styled('div')`
  white-space: nowrap;
  overflow: visible;
`;

const StyledPopper = styled(Popper)`
  & .${tooltipClasses.tooltip} {
    background-color: ${(p) => p.theme.palette.background.base};
    color: ${(p) => p.theme.palette.text.primary};
    box-shadow: ${(p) => p.theme.shadows[2]};
    padding: 0;
    max-height: 75vh;
    overflow: auto;
  }
`;

type UserDropdownProps = {
  userDropdown: {
    userName: string;
    medicalGroupName: string;
    dropdownSections: Array<DropdownSection>;
  };
  sx: SxProps;
};

export default function UserDropdown({ userDropdown, sx }: UserDropdownProps) {
  const { userName, medicalGroupName, dropdownSections } = userDropdown;

  return (
    <UserDropdownRoot sx={sx}>
      <Tooltip
        disableFocusListener
        title={<DropdownMenu dropdownSections={dropdownSections} />}
        arrow={false}
        placement="bottom-end"
        PopperComponent={StyledPopper}
      >
        <Box
          data-pendo-id="navbar-user-dropdown"
          component="button"
          type="button"
          sx={{
            backgroundColor: 'transparent',
            border: 'none',
            px: 1.5,
            py: 1,
            textAlign: 'left',
            transition: `width ${TRANSITION_TIMEOUT}`,
            '&:hover': {
              color: 'text.link',
            },
          }}
        >
          <Typography variant="bodymedium">{userName}</Typography>
          <Typography variant="bodymediumbold">{medicalGroupName}</Typography>
        </Box>
      </Tooltip>
    </UserDropdownRoot>
  );
}
