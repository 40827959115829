import {
  Box,
  Button,
  Modal,
  Paper,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

import useStopAutomatedOutreach from '../../hooks/useStopAutomatedOutreach';

function OutreachCampaignStopModal({
  open,
  onCancel,
  onClose,
  patientName,
  patientId,
  refreshLogOutreach,
}) {
  const { mutate } = useStopAutomatedOutreach();
  const theme = useTheme();

  const onSuccess = () => {
    onClose();
    refreshLogOutreach();
  };
  const onSubmit = () => {
    mutate({ patientId }, { onSuccess });
  };
  return (
    <Modal open={open}>
      <StyledPaper>
        <Box
          sx={{
            p: 3,
            borderBottom: '1px solid',
            borderBottomColor: 'stroke.light',
          }}
        >
          <Typography variant="h2">Stop automated outreach</Typography>
          <Typography>{patientName}</Typography>
          <StyledTypography>
            Stellar will discontinue outreach attempts to this patient on the
            next business day.
          </StyledTypography>
        </Box>

        <Box
          sx={{
            bgcolor: 'background.secondary',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme.spacing(2, 3),
          }}
        >
          <Button
            data-pendo-id="outreach-campaign-stop-cancel-button"
            onClick={onCancel}
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            data-pendo-id="outreach-campaign-stop-submit-button"
            type="submit"
            size="large"
            sx={{
              marginLeft: 2,
            }}
            onClick={onSubmit}
          >
            Stop outreach now
          </Button>
        </Box>
      </StyledPaper>
    </Modal>
  );
}

OutreachCampaignStopModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  patientName: PropTypes.string,
  patientId: PropTypes.number,
  refreshLogOutreach: PropTypes.func.isRequired,
};

OutreachCampaignStopModal.defaultProps = {
  patientName: '',
  patientId: null,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: `${4 * theme.shape.borderRadius}px`,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  position: 'absolute',
  width: '638px',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export default OutreachCampaignStopModal;
