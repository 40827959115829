import {
  Box,
  Modal,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { array, bool, func, node, object, string } from 'prop-types';
import useId from '@mui/utils/useId';

import themeV2 from '@/themeV2';
import DynamicForm from '@/components/DynamicForm/DynamicForm';
import DynamicFormButtons from '@/components/DynamicForm/DynamicFormButtons';
import DynamicFormFields from '@/components/DynamicForm/DynamicFormFields';
import DynamicFormRadioGroup from '@/components/DynamicForm/fieldComponents/DynamicFormRadioGroup';
import DynamicFormTextareaV2 from '@/components/DynamicForm/fieldComponents/DynamicFormTextareaV2';
import OutreachCampaignControlButton from '@/pages/TaskBasedWorkflow/components/patientDetailView/OutreachCampaignControlButton';

function fieldComponentMapper(field) {
  switch (field.type) {
    case 'text':
    case 'textarea':
      return DynamicFormTextareaV2;
    case 'radiogroup':
    case 'radio':
      return DynamicFormRadioGroup;
    default:
      // Fall back to the default field component
      return null;
  }
}

export default function BaseFormModal({
  alert,
  defaultValues,
  fields,
  isLoading,
  onCancel,
  onClose,
  onSubmit,
  open,
  title,
  headerVariant,
  footer,
  modalSx,
  cancelButtonPendoId,
  submitButtonPendoId,
  submitButtonLabel,
  additionalProps,
  fieldComponentMapperOverride,
}) {
  const theme = useTheme();
  const titleId = useId();

  return (
    <ThemeProvider theme={themeV2}>
      <Modal aria-labelledby={titleId} open={open} onClose={onClose}>
        <StyledPaper sx={modalSx}>
          <DynamicForm
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            mode="onChange"
          >
            <Box
              sx={{
                p: 3,
                borderBottom: '1px solid',
                borderBottomColor: 'stroke.light',
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography id={titleId} variant={headerVariant}>
                  {title}
                </Typography>
                {additionalProps?.campaign !== undefined && (
                  <OutreachCampaignControlButton
                    campaign={additionalProps?.campaign}
                    handleOpenStartModal={additionalProps?.handleOpenStartModal}
                    handleOpenStopModal={additionalProps?.handleOpenStopModal}
                  />
                )}
              </Stack>

              {alert ? <Box mt={1.5}>{alert}</Box> : null}

              <DynamicFormFields
                fieldComponentMapper={
                  fieldComponentMapperOverride || fieldComponentMapper
                }
                fields={fields}
                additionalProps={additionalProps}
              />
              {footer ? <Box mt={1.5}>{footer}</Box> : null}
            </Box>
            <Box
              sx={{
                bgcolor: 'background.secondary',
                display: 'flex',
                justifyContent: 'flex-end',
                padding: theme.spacing(2, 3),
              }}
            >
              <DynamicFormButtons
                onCancel={onCancel}
                ButtonSx={{
                  marginLeft: 2,
                }}
                isLoading={isLoading}
                cancelButtonPendoId={cancelButtonPendoId}
                submitButtonPendoId={submitButtonPendoId}
                submitButtonLabel={submitButtonLabel}
              />
            </Box>
          </DynamicForm>
        </StyledPaper>
      </Modal>
    </ThemeProvider>
  );
}

BaseFormModal.propTypes = {
  alert: node,
  /* eslint-disable react/forbid-prop-types */
  defaultValues: object,
  fields: array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  isLoading: bool,
  onCancel: func.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  open: bool.isRequired,
  title: string.isRequired,
  headerVariant: string,
  footer: node,
  modalSx: object,
  additionalProps: object,
  cancelButtonPendoId: string,
  submitButtonPendoId: string,
  submitButtonLabel: string,
  fieldComponentMapperOverride: func,
};

BaseFormModal.defaultProps = {
  alert: undefined,
  defaultValues: {},
  isLoading: false,
  headerVariant: 'h4',
  footer: undefined,
  modalSx: {},
  additionalProps: {},
  cancelButtonPendoId: undefined,
  submitButtonPendoId: undefined,
  submitButtonLabel: undefined,
  fieldComponentMapperOverride: undefined,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: `${4 * theme.shape.borderRadius}px`,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'hidden',
  position: 'absolute',
  width: '360px',
}));
