import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { formatISO } from 'date-fns';
import { identity } from 'lodash';

import useAxios from '@/hooks/useAxios';

import {
  fetchCompletedActions,
  fetchV1ActionFormStates,
  fetchV1Summary,
  fetchV2Actions,
} from './fetch';
import transformResponses from './transformResponses';

/**
 * @typedef Actions
 * @property {Action[]} incomplete
 * @property {Action[]} complete
 */
/**
 * @typedef ActionMap
 * @property {Actions} recaptureDiagnosis
 * @property {Actions} suggestedDiagnosis
 * @property {Actions} nonDiagnosis
 */

/**
 * @typedef PatientSummaryResponse
 * @property {'error'|'loading'|'success'|'pending'} status
 * @property {boolean} isLoading
 * @property {boolean} isError
 * @property {boolean} isSuccess
 * @property {boolean} isPending
 * @property {Patient} patient
 * @property {ActionMap} actions
 * @property {Record<string, unknown>?} hccTrumping
 * @property {Record<string, boolean>?} featureFlags
 * @property {Record<string, Record<string, unknown>>} formState
 * @property {number|string} defaultProviderChoiceId
 * @property {{ id: number, name: string }[]} providers
 */

/**
 * @typedef SummaryDataArgs
 * @property {number} patientId
 * @property {Date?} dateOfService
 * @property {Boolean?} fetchCompleted
 * @property {Boolean?} isEnabled
 */

/**
 * Given a patient id, fetch data from many HTTP endpoints, transform it, and
 * return it to the component for easy rendering.
 *
 * @param args {SummaryDataArgs}
 * @returns {PatientSummaryResponse}
 */
export default function useSummaryData({
  patientId,
  dateOfService,
  fetchCompleted = true,
  isEnabled = true,
  select = identity,
}) {
  const axios = useAxios();
  const enabled = isEnabled && Boolean(patientId);
  const dateOfServiceSerialized = formatISO(dateOfService);

  // Request all the data we want in parallel. We can't really action any of it
  // until all of it is loaded, because it's going to get merged together in
  // some interesting ways. To that end, adopt the following algo:
  //
  // 1. If any response is error, yield an error state
  // 2. If any response is loading, yield a loading state
  // 3. If all responses are loaded, process and return a data object that has
  //    some nice ergonomics for consumers.
  const responses = useQueries({
    queries: [
      {
        queryKey: [
          'ehr-summary',
          patientId,
          'summary',
          dateOfServiceSerialized,
        ],
        queryFn: () => fetchV1Summary(axios, patientId, dateOfService),
        enabled,
      },
      {
        queryKey: [
          'ehr-summary',
          patientId,
          'form-state',
          dateOfServiceSerialized,
        ],
        queryFn: () => fetchV1ActionFormStates(axios, patientId, dateOfService),
        enabled,
      },
      {
        queryKey: [
          'ehr-summary',
          patientId,
          'completed-actions',
          dateOfServiceSerialized,
        ],
        queryFn: () => fetchCompletedActions(axios, patientId, dateOfService),
        enabled: enabled && fetchCompleted,
      },
      {
        queryKey: [
          'ehr-summary',
          patientId,
          'v2-actions',
          dateOfServiceSerialized,
        ],
        queryFn: () => fetchV2Actions(axios, patientId, dateOfService),
        enabled,
      },
    ],
  });
  const [
    summaryResponse,
    formStateResponse,
    completedActions,
    v2ActionsResponse,
  ] = responses;

  const anyErrors = [
    summaryResponse,
    formStateResponse,
    // don't care about completedActions, if that request fails we just
    // assume there are no completed actions.
    v2ActionsResponse,
  ].some((r) => r.isError);

  return useMemo(() => {
    if (!enabled) {
      return {
        status: 'pending',
        isLoading: false,
        isError: false,
        isSuccess: false,
        isPending: true,
      };
    }

    const anyLoading =
      enabled &&
      responses.some(
        (r) => r.isLoading && (r !== completedActions || fetchCompleted),
      );

    if (anyErrors) {
      return {
        status: 'error',
        isPending: false,
        isLoading: false,
        isError: true,
        isSuccess: false,
        errors: responses.filter((r) => r.isError).map((r) => r.error.response),
      };
    }
    if (anyLoading) {
      return {
        status: 'loading',
        isLoading: true,
        isError: false,
        isSuccess: false,
        isPending: false,
      };
    }

    return {
      status: 'success',
      isLoading: false,
      isError: false,
      isSuccess: true,
      isPending: false,
      ...select(
        transformResponses({
          summaryData: summaryResponse.data,
          formStateData: formStateResponse.data,
          completedActions: completedActions.data,
          v2ActionsData: v2ActionsResponse.data,
        }),
      ),
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // This rather unpleasant list of dependencies is here because react-query
    // doesn't offer the same reference identity for `responses` or for any
    // individual response between renders. It _does_ treat all sub-properties
    // of responses as reference-stable. So we use the status property as a
    // shorthand for detecting if the request state has changed.
    //
    // We care about this because the transformResponses function does some
    // fairly serious data transformation that shouldn't happen on every
    // render cycle.
    summaryResponse.data,
    formStateResponse.data,
    completedActions.data,
    v2ActionsResponse.data,
    anyErrors,
  ]);
}
