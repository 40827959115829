import { Typography } from '@mui/material';
import { isValid, parse } from 'date-fns';
import PropTypes from 'prop-types';

import { maybeFormatDate } from '@/pages/PrintSummary/utils/printUtils';

import { groupClaimShape } from '../utils/printPropTypes';
import {
  StyledVisitsAndDxHistoryTable,
  VisitsAndDxHistoryTableColumnLabel,
  VisitsAndDxHistoryTableHeader,
  VisitsAndDxHistoryTableSubHeader,
} from '../utils/printStyles';

function DiagnosisText({ diagnosisCode, description }) {
  return (
    <Typography component="p" sx={{ fontSize: '14px' }}>
      <Typography fontWeight="bold" component="span" sx={{ fontSize: '14px' }}>
        {diagnosisCode}{' '}
      </Typography>
      {description && (
        <Typography component="span" sx={{ fontSize: '14px' }}>
          {' - '}
          {description}
        </Typography>
      )}
    </Typography>
  );
}
DiagnosisText.propTypes = {
  diagnosisCode: PropTypes.string.isRequired,
  description: PropTypes.string,
};
DiagnosisText.defaultProps = {
  description: undefined,
};

export function areSameDay(fromDateStr, thruDateStr) {
  const parsedFromDate = parse(fromDateStr, 'yyyy-MM-dd', new Date());
  const parsedThruDate = parse(thruDateStr, 'yyyy-MM-dd', new Date());
  if (isValid(parsedFromDate) && isValid(parsedThruDate)) {
    return (
      parsedFromDate.getFullYear() === parsedThruDate.getFullYear() &&
      parsedFromDate.getMonth() === parsedThruDate.getMonth() &&
      parsedFromDate.getDate() === parsedThruDate.getDate()
    );
  }
  return false;
}

export function VisitGroupDate({ fromDate, thruDate }) {
  if (areSameDay(fromDate, thruDate)) {
    return (
      <Typography component="p" sx={{ fontSize: '14px' }}>
        {maybeFormatDate(fromDate)}
      </Typography>
    );
  }
  return (
    <>
      <Typography component="p" sx={{ fontSize: '14px' }}>
        {maybeFormatDate(fromDate)} -{' '}
      </Typography>
      <Typography component="p" sx={{ fontSize: '14px' }}>
        {maybeFormatDate(thruDate)}{' '}
      </Typography>
    </>
  );
}

VisitGroupDate.propTypes = {
  fromDate: PropTypes.string.isRequired,
  thruDate: PropTypes.string.isRequired,
};

function InpatientAndEdVisitRow({ visit }) {
  const visitType = visit.isEd ? 'ED' : 'Inpatient';
  const likelyAvoidable = (() => {
    if (visit.likelyAvoidable === true) {
      return 'Yes';
    }
    if (visit.likelyAvoidable === false) return 'No';
    return '--';
  })();
  const facility = visit.facilityName;
  return (
    <tr>
      <td>
        <VisitGroupDate
          fromDate={visit.groupFromDate}
          thruDate={visit.groupThruDate}
        />
      </td>
      <td>{visitType}</td>
      <td>{likelyAvoidable}</td>
      <td>{facility}</td>
      <td>
        {visit.mostRecentDiagnoses.map((dx) => (
          <DiagnosisText
            key={dx.id}
            diagnosisCode={dx.diagnosisCode}
            description={dx.description}
          />
        ))}
      </td>
    </tr>
  );
}

export function getVisitKey(visit) {
  return [
    visit.groupFromDate,
    visit.groupThruDate,
    ...visit.claimRows.map((c) => c.id),
  ].join('-');
}

function InpatientAndEdVisitsTable({ visits }) {
  return (
    <StyledVisitsAndDxHistoryTable>
      <thead>
        <tr>
          <td colSpan={5}>
            <VisitsAndDxHistoryTableHeader component="span">
              Inpatient and ED Visits{' '}
            </VisitsAndDxHistoryTableHeader>
            <VisitsAndDxHistoryTableSubHeader component="span">
              {visits.length} in last 12 months
            </VisitsAndDxHistoryTableSubHeader>
          </td>
        </tr>
        <tr>
          <td>
            <VisitsAndDxHistoryTableColumnLabel>
              Date
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
          <td>
            <VisitsAndDxHistoryTableColumnLabel>
              Type
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
          <td>
            <VisitsAndDxHistoryTableColumnLabel>
              Likely avoidable?
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
          <td>
            <VisitsAndDxHistoryTableColumnLabel>
              Facility
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
          <td width="40%">
            <VisitsAndDxHistoryTableColumnLabel>
              Diagnoses
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
        </tr>
      </thead>
      <tbody>
        {visits.map((visit) => (
          <InpatientAndEdVisitRow key={getVisitKey(visit)} visit={visit} />
        ))}
      </tbody>
    </StyledVisitsAndDxHistoryTable>
  );
}
export default InpatientAndEdVisitsTable;

InpatientAndEdVisitsTable.propTypes = {
  visits: PropTypes.arrayOf(groupClaimShape).isRequired,
};

InpatientAndEdVisitRow.propTypes = {
  visit: groupClaimShape.isRequired,
};

export { DiagnosisText };
