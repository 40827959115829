import PropTypes from 'prop-types';
import { Slide, styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { usePrevious } from 'react-use';
import { concat, first, map } from 'lodash';
import produce from 'immer';

import { useQueryParameters } from '@/contexts/QueryParametersContext';
import { tbwTransformFrontendAction } from '@/utils/transformFrontendAction';
import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import {
  useCompletedPatientTasks,
  useCompletedTasksContext,
  useRemovedPatientTasks,
} from '@/pages/TaskBasedWorkflow/contexts/CompletedTasksContext';

import { Page, TRANSITION_TIMEOUT } from '../constants';
import queryKeyFactory from '../queryKeyFactory';
import useGetPatientTasks from '../hooks/useGetPatientTasks';
import { usePage } from '../contexts/PageContext';

import PatientBanner from './patientDetailView/PatientBanner';
import CenterPanel from './taskDetailView/CenterPanel';
import StepExecutionPanel from './patientDetailView/StepExecutionPanel';
import TaskPanel from './taskDetailView/TaskPanel';
import PatientSummaryModalButton from './taskDetailView/PatientSummaryModalButton';

const CENTER_PANEL_MAX_WIDTH = 1000; // in pixels
const EXECUTION_PANEL_WIDTH = 456; // in pixels
const XXL_BREAKPOINT = 1700; // in pixels
const SLIDE_DELAY_INTERVAL = 200; // in milliseconds

function findActionAndStep(tasks, { actionId, stepId }) {
  const action = tasks.find(
    (userInputTask) => userInputTask.action.id === actionId,
  )?.action;
  const step = action?.v2Dto.availableNextSteps.find(
    (step) => step.stepId === stepId,
  );

  return {
    step: step ?? undefined,
    action,
  };
}

/**
 * Finds the corresponding task for a given action from a list of user input tasks.
 */
function findTask(action, tasks) {
  return tasks.find((task) => task.action.id === action.id);
}

/**
 * Map a list of actions to their corresponding tasks.
 * @param actions - a list of actions
 * @param tasks - the list of all tasks
 */
function mapActionsToTasks(actions, tasks) {
  return actions.map((action) => {
    const task = findTask(action, tasks);
    return produce(task, (draft) => {
      draft.action = action;
    });
  });
}

/**
 * Returns true if the action has no more user actions available.
 */
function isUserComplete(action) {
  return Boolean(
    !action.v2Dto.availableNextSteps ||
      action.v2Dto.availableNextSteps.length === 0,
  );
}

export default function TaskDetailView({
  tasks,
  onClickShowMore,
  isFetchingNextPage,
  hasNextPage,
  showPatientSummaryModal,
}) {
  const { addCompletedTasks, addRemovedTasks } = useCompletedTasksContext();

  const {
    parameters: { actionId, stepId, patientId, openFirstPatient },
    mergeParameters,
  } = useQueryParameters();
  // Delay the StepExecutionPanel slide-in animation when the user first lands on the page to help orient the user a little better.
  const [isDelaying, setIsDelaying] = useState(() => Boolean(stepId));
  const page = usePage();
  const queryClient = useQueryClient();

  const {
    data = {},
    isLoading,
    isSuccess,
  } = useGetPatientTasks({
    patientId,
    // Always provide hidden tasks to CenterPanel. The task filtering logic is in that component.
    includeHidden: true,
    onSuccess: (data) => {
      if (!data) {
        // The patientId is invalid. Kick the user back to the table view.
        mergeParameters({
          patientId: undefined,
          actionId: undefined,
          stepId: undefined,
          taskId: undefined,
        });
      }
    },
  });

  const completedPatientTasks = useCompletedPatientTasks(patientId);
  const completedPatientTasksIds = map(completedPatientTasks, 'id');
  const removedPatientTasks = useRemovedPatientTasks(patientId);
  const removedPatientTasksIds = map(removedPatientTasks, 'id');

  const patientTasks = (data?.results || []).filter((x) => {
    // There's a brief moment while the query is refetching that completed/removed tasks can appear twice,
    // so make sure to remove duplicates.
    return (
      !completedPatientTasksIds.includes(x.id) &&
      !removedPatientTasksIds.includes(x.id)
    );
  });

  // The patient should be the same for each patient task, so grab the patient from the first task.
  const selectedPatient =
    first(patientTasks)?.patient ??
    first(completedPatientTasks)?.patient ??
    first(removedPatientTasks)?.patient;

  useEffect(() => {
    // This effect updates isDelaying after the delay interval has passed.
    // The delay doesn't start counting until after the patient data is loaded.
    let timeout;
    if (isDelaying && isSuccess) {
      timeout = setTimeout(() => setIsDelaying(false), SLIDE_DELAY_INTERVAL);
      if (tasks && openFirstPatient) {
        mergeParameters({
          patientId: first(tasks).patient.id,
          openFirstTask: true,
          openFirstPatient: undefined,
        });
      }
    }
    return () => clearTimeout(timeout);
  }, [isDelaying, isSuccess, mergeParameters, openFirstPatient, tasks]);

  const {
    // The selected action isn't actually selected by the user.
    // It is the action associated with the selected step.
    action: selectedAction,
    step: selectedStep,
  } = useMemo(() => {
    if (isLoading) return { action: undefined, step: undefined };

    return findActionAndStep(patientTasks, { actionId, stepId });
  }, [actionId, isLoading, patientTasks, stepId]);

  const handleSuccessExecuteStep = async (response) => {
    // It's possible for one or multiple actions to be updated at once, so we need to handle both cases.
    // Convert the payload to an array if it's not already one.
    const updatedActions = concat(response.data).map((x) =>
      tbwTransformFrontendAction(x.action),
    );
    const completedActions = updatedActions.filter(isUserComplete);
    const completedTasks = mapActionsToTasks(updatedActions, patientTasks);

    if (completedActions.length) {
      // Locally store the completed tasks, as they are removed from the server response after being completed.
      addCompletedTasks(...completedTasks);
    }

    // Refetch stale data
    window.stellar?.refreshSvuTracker?.();
    await Promise.all([
      queryClient.invalidateQueries(queryKeyFactory.taskCounts()),
      queryClient.invalidateQueries(
        queryKeyFactory.patientTasks({ patientId: selectedPatient.id }),
      ),
      queryClient.invalidateQueries(
        queryKeyFactory.patientExperienceLandingData(),
        { type: 'all' },
      ),
    ]);

    if (page !== Page.patientExperience) {
      // Collapse the step execution panel
      mergeParameters({
        actionId: undefined,
        stepId: undefined,
      });
    }
  };

  const handleRemoveStep = async (response) => {
    // It's possible for one or multiple actions to be removed at once, so we need to handle both cases.
    // Convert the payload to an array if it's not already one.
    const updatedActions = concat(response.data).map((x) =>
      tbwTransformFrontendAction(x.action),
    );
    const removedTasks = mapActionsToTasks(updatedActions, patientTasks);

    // Locally store the removed tasks, as they are removed from the server response after being removed.
    addRemovedTasks(...removedTasks);

    await Promise.all([
      queryClient.invalidateQueries(queryKeyFactory.taskCounts()),
      queryClient.invalidateQueries(
        queryKeyFactory.patientTasks({ patientId: selectedPatient.id }),
      ),
    ]);
  };

  const handleCancelExecuteStep = () => {
    mergeParameters({
      stepId: undefined,
      actionId: undefined,
    });
  };

  const handleSelectStep = (step, action) => {
    mergeParameters({
      actionId: action.id,
      stepId: step.stepId,
    });
  };

  // These values are used so that the StepExecutionPanel isn't blank while it's sliding out.
  const previousSelectedPatient = usePrevious(selectedPatient);
  const previousSelectedAction = usePrevious(selectedAction);
  const previousSelectedStep = usePrevious(selectedStep);
  const panelIn = Boolean(selectedStep && !isDelaying);

  return (
    <TaskDetailViewRoot>
      <TaskPanel
        tasks={tasks}
        onClickShowMore={onClickShowMore}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
      />

      <ContentContainer>
        {selectedPatient ? (
          <>
            <PatientBanner
              hidePriority
              patient={selectedPatient}
              selectedAction={selectedAction || previousSelectedAction}
              sx={{ gridColumn: '1 / -1', backgroundColor: 'transparent' }}
            />

            <CenterPanelColumn $panelIn={panelIn}>
              <CenterPanel
                patient={selectedPatient}
                userInputTasks={patientTasks}
                onRemoveStep={handleRemoveStep}
                onSelectStep={handleSelectStep}
                selectedAction={selectedAction}
                selectedStep={selectedStep}
                sx={(theme) => ({
                  maxWidth: CENTER_PANEL_MAX_WIDTH,
                  [theme.breakpoints.down('lg')]: {
                    width: '540px',
                  },
                })}
              />

              {showPatientSummaryModal && (
                <PatientSummaryModalButton patientId={patientId} />
              )}
            </CenterPanelColumn>

            <Slide
              direction="left"
              in={panelIn}
              mountOnEnter
              unmountOnExit
              timeout={TRANSITION_TIMEOUT}
            >
              <StepExecutionPanelColumn>
                <StepExecutionPanel
                  step={selectedStep || previousSelectedStep}
                  patient={selectedPatient || previousSelectedPatient}
                  action={selectedAction || previousSelectedAction}
                  patientTasks={patientTasks}
                  onSuccess={handleSuccessExecuteStep}
                  onCancel={handleCancelExecuteStep}
                />
              </StepExecutionPanelColumn>
            </Slide>
          </>
        ) : null}
      </ContentContainer>
    </TaskDetailViewRoot>
  );
}

TaskDetailView.propTypes = {
  tasks: PropTypes.arrayOf(userInputTaskShape),
  hasNextPage: PropTypes.bool,
  onClickShowMore: PropTypes.func.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,
  showPatientSummaryModal: PropTypes.bool,
};

TaskDetailView.defaultProps = {
  hasNextPage: false,
  tasks: undefined,
  showPatientSummaryModal: false,
};

const TaskDetailViewRoot = styled('div')`
  background-color: ${(p) => p.theme.palette.background.secondary};
  display: grid;
  grid-template-columns: [tasks-panel] 356px 1fr;
  height: 100%;
`;

const ContentContainer = styled('div')`
  display: grid;
  min-height: 0;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr ${EXECUTION_PANEL_WIDTH}px;
  border-left: 1px solid ${(p) => p.theme.palette.border.input};
  ${(p) => p.theme.breakpoints.up(XXL_BREAKPOINT)} {
    // Don't allow the gap between the Action Cards and Step Execution Panel get too large.
    // At this xxl breakpoint, make the Step Execution Panel grow larger instead.
    grid-template-columns: ${CENTER_PANEL_MAX_WIDTH}px 1fr;
  }
`;

const CenterPanelColumn = styled('div')`
  min-width: 0; // Allows horizontal scroll
  overflow: visible;
  border-right: 1px solid ${(p) => p.theme.palette.border.base};
`;

const StepExecutionPanelColumn = styled('div')`
  background-color: ${(p) => p.theme.palette.background.base};
  overflow: auto;
  z-index: 1; // Render over the center panel on small breakpoints
`;
