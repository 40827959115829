import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

type Params = {
  patientId: number;
  appointmentId?: number | null;
  actionIds: Record<number, boolean>;
  formatting: string;
  wasSelectAllChecked: boolean;
};

async function logCopyPaste(params: Params) {
  return axios.post<void>('/api/copypaste/audit', params);
}

export default function useLogCopyPasteAudit() {
  return useMutation(logCopyPaste);
}
