import { Alert, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import produce from 'immer';
import { useState } from 'react';

import { ReactComponent as RemoveIcon } from '@/assets/icons/removeV2.svg';
import { stepShape } from '@/utils/transformFrontendCareGapAction';
import { actionShape } from '@/utils/transformFrontendAction';
import useBulkExecuteSteps from '@/pages/TaskBasedWorkflow/hooks/useBulkExecuteSteps';
import {
  BULK_REMOVE,
  appendExecutionRequirements,
  relatedActionShape,
  transformBulkRemoval,
} from '@/utils/relatedActions';

import BaseFormWidget from '../../../../../components/BaseFormWidget';
import useExecuteStep from '../../../hooks/useExecuteStep';
import ErrorAlert from '../../ErrorAlert';

export default function RemoveActionWidget({
  action,
  onRemoveStep,
  removalStep,
  showAlert,
  relatedActions,
}) {
  const [showError, setShowError] = useState(false);
  const onError = () => {
    setShowError(true);
  };

  const [showPopover, setShowPopover] = useState(false);
  const { mutate } = useExecuteStep({ onSuccess: onRemoveStep });
  const { mutate: mutateBulk } = useBulkExecuteSteps({
    onSuccess: onRemoveStep,
    onError,
  });

  const handleCancel = () => {
    setShowError(false);
    setShowPopover(false);
  };

  const handleIconClick = () => {
    if (!showPopover) {
      setShowPopover(true);
    }
  };

  const onSubmit = (data) => {
    let bulkRemove = false;
    const executionInput = produce(data, (draft) => {
      // notes can't be blank. Remove if it is falsy.
      if (!data.notes) {
        delete draft.notes;
      }
      Object.entries(data).forEach(([key, value]) => {
        // suppress bulk remove as backend is not expecting it
        if (key === BULK_REMOVE) {
          bulkRemove = value;
          delete draft[key];
        }
        if (value === '') {
          delete draft[key];
        }
      });
    });

    if (bulkRemove) {
      mutateBulk(transformBulkRemoval(relatedActions, executionInput));
    } else {
      mutate({
        actionId: action.id,
        stepId: removalStep.stepId,
        executionInput,
      });
    }
  };

  const theme = useTheme();
  const removeExecutionRequirements = appendExecutionRequirements(
    removalStep.modifiedExecutionRequirements,
    relatedActions,
    action.id,
  );

  return removeExecutionRequirements ? (
    <BaseFormWidget
      alert={
        <>
          {showError ? <ErrorAlert message="Unable to remove" /> : null}
          {showAlert ? (
            <Alert severity="info">
              <Typography>
                <Typography component="span" variant="bodybold">
                  {action.v2Dto.description}
                </Typography>{' '}
                will be permanently removed for this patient and you won’t be
                prompted to complete it again.
              </Typography>
            </Alert>
          ) : null}
        </>
      }
      IconComponent={RemoveIcon}
      iconAriaLabel="Remove action"
      onCancel={handleCancel}
      onIconClick={handleIconClick}
      onSubmit={onSubmit}
      cancelButtonPendoId="worklist-patient-remove-cancel"
      iconPendoId="worklist-patient-remove"
      iconPrimaryActiveColor={theme.palette.icon.placeholder}
      iconSecondaryActiveColor="default"
      submitButtonLabel="Confirm"
      title="Remove"
      fields={removeExecutionRequirements}
      showPopover={showPopover}
    />
  ) : null;
}

RemoveActionWidget.propTypes = {
  action: actionShape.isRequired,
  onRemoveStep: PropTypes.func.isRequired,
  removalStep: stepShape.isRequired,
  showAlert: PropTypes.bool,
  relatedActions: PropTypes.arrayOf(relatedActionShape),
};

RemoveActionWidget.defaultProps = {
  showAlert: false,
  relatedActions: [],
};
