import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { merge, reduce } from 'lodash';
import { format } from 'date-fns';
import { AxiosResponse } from 'axios';

// @ts-expect-error - js module
import BaseCheckbox from '@/components/BaseCheckbox';
// @ts-expect-error - js module
import BaseTextarea from '@/components/BaseTextarea';
// @ts-expect-error - js module
import BaseModal from '@/components/BaseModal';
// @ts-expect-error - js module
import BaseForm from '@/components/BaseForm';
import useBulkExecuteSteps, {
  BulkExecuteStepsPayload,
  BulkExecuteStepsResult,
} from '@/pages/TaskBasedWorkflow/hooks/useBulkExecuteSteps';
import { Action } from '@/utils/types';

type ExecutionInput = {
  completed_date: string;
  notes: string | undefined;
  pcp_visit_servicing_provider_id: number;
};

function buildBulkExecuteStepsPayload(
  actions: Array<Action>,
  executionInput: {
    completedDate: Date;
    notes: string;
    servicingProviderId: number;
  },
) {
  return reduce(
    actions,
    (acc, action) => {
      acc[action.id] = {
        step_id: action.v2Dto.preferredPath[0]!.stepId,
        execution_input: {
          completed_date: format(executionInput.completedDate, 'yyyy-MM-dd'),
          notes: executionInput.notes ? executionInput.notes : undefined,
          pcp_visit_servicing_provider_id: executionInput.servicingProviderId,
        },
      };
      return acc;
    },
    {} as BulkExecuteStepsPayload<ExecutionInput>,
  );
}

type Props = {
  action: Action;
  otherRxPickupActions: Array<Action>;
  submittedData?: {
    pcp_visit_servicing_provider_id: number;
    completed_date: Date;
    notes: string;
  };
  open: boolean;
  onSuccess: (data: AxiosResponse<BulkExecuteStepsResult>) => void;
};

export default function RxPickupBulkUpdateModal({
  action,
  otherRxPickupActions,
  submittedData = undefined,
  open,
  onSuccess,
}: Props) {
  const [selectedActionIds, setSelectedActionIds] = useState<Array<number>>([]);
  const [notes, setNotes] = useState('');
  const { mutate, isLoading } = useBulkExecuteSteps({ onSuccess });

  if (!submittedData) return null;

  const {
    completed_date: completedDate,
    pcp_visit_servicing_provider_id: servicingProviderId,
  } = submittedData;

  const handleSubmit = () => {
    const selectedActions = otherRxPickupActions.filter((x) =>
      selectedActionIds.includes(x.id),
    );
    const payload = merge(
      buildBulkExecuteStepsPayload(selectedActions, {
        completedDate,
        servicingProviderId,
        notes,
      }),
      buildBulkExecuteStepsPayload([action], {
        completedDate,
        servicingProviderId,
        notes: submittedData.notes,
      }),
    );
    mutate(payload);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, taskId: number) => {
    if (e.target.checked) {
      setSelectedActionIds((oldVal) => [...oldVal, taskId]);
    } else {
      setSelectedActionIds((oldVal) => oldVal.filter((id) => id !== taskId));
    }
  };

  const handleChangeNote = (e: ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  return (
    <BaseModal title="Confirmed" open={open} sx={{ maxWidth: '586px' }}>
      <p>
        Were any of these other late pickups also addressed with the patient on{' '}
        <Typography variant="bodylargebold">
          {format(completedDate, 'MM-dd-yyyy')}
        </Typography>
        ?
      </p>

      <BaseForm onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <FormGroup>
            {otherRxPickupActions.map((otherAction) => (
              <BaseCheckbox
                key={otherAction.id}
                label={otherAction.v2Dto.description}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, otherAction.id)
                }
              />
            ))}
          </FormGroup>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <FormLabel>Note (optional)</FormLabel>
          <BaseTextarea onChange={handleChangeNote} fullWidth />
        </FormControl>

        <Stack spacing={2} direction="row" mt={2} justifyContent="flex-end">
          <Button
            disabled={isLoading}
            onClick={handleSubmit}
            variant="secondary"
          >
            Proceed without closing
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={selectedActionIds.length === 0 || isLoading}
          >
            Close selected action(s)
          </Button>
        </Stack>
      </BaseForm>
    </BaseModal>
  );
}
