/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputBase,
  Paper,
  PaperProps,
  Popper,
  Stack,
  SxProps,
  Theme,
  Typography,
  autocompleteClasses,
  inputBaseClasses,
  styled,
} from '@mui/material';
import { MouseEventHandler, SyntheticEvent, useState } from 'react';
import { format, isValid, parseISO } from 'date-fns';

import useDebouncedValue from '@/utils/useDebouncedValue';
import { ReactComponent as MagnifyingGlassIcon } from '@/assets/icons/magnifyingGlass.svg';
import useFeatureFlag from '@/utils/useFeatureFlag';
// @ts-expect-error - js module
import usePendoId from '@ob/hooks/usePendoId';

import useGetSearchPatients, { Patient } from '../hooks/useGetSearchPatients';

const COLUMN_SPACING = 1;

const StyledPopper = styled(Popper)`
  .${autocompleteClasses.paper} {
    min-width: 300px;
  }
  .${autocompleteClasses.listbox} {
    padding: 0;
    max-height: 60vh;
  }
  .${autocompleteClasses.option}.Mui-focused {
    background-color: ${(p) => p.theme.palette.background.primaryLightest};
  }
`;

const StyledInput = styled(InputBase)`
  border: 1px solid ${(p) => p.theme.palette.border.input};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(0, 0.75)};
  width: 100%;
  .${inputBaseClasses.input} {
    background-color: transparent;
  }
`;

/**
 * Returns a date string in the MM-DD-YYYY format if the input is valid. Otherwise, returns the input.
 * @param input An ISO date string
 */
function safeFormatDate(input: string) {
  const date = parseISO(input);
  return isValid(date) ? format(date, 'MM-dd-yyyy') : input;
}

function getOptionLabel(option: Patient) {
  return `${option.firstName} ${option.lastName}`;
}

function PaperWithHeaderPrintEnabled({ children, ...otherProps }: PaperProps) {
  return (
    <Paper {...otherProps}>
      <Grid container px={2} pt={1} pb={0.5} columnSpacing={COLUMN_SPACING}>
        <Grid item xs={5}>
          <Typography component="span" variant="bodymediumbold">
            Patient
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography component="span" variant="bodymediumbold">
            PCP
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      {children}
    </Paper>
  );
}

function PaperWithHeaderPrintNotEnabled({
  children,
  ...otherProps
}: PaperProps) {
  return (
    <Paper {...otherProps}>
      <Grid container px={2} pt={1} pb={0.5} columnSpacing={COLUMN_SPACING}>
        <Grid item xs={6}>
          <Typography component="span" variant="bodymediumbold">
            Patient
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="span" variant="bodymediumbold">
            PCP
          </Typography>
        </Grid>
      </Grid>
      {children}
    </Paper>
  );
}

type PatientSearchProps = {
  sx: SxProps<Theme>;
};
export default function PatientSearch({ sx }: PatientSearchProps) {
  const pendoId = usePendoId({ prefix: ['global', 'navbar'] });
  const [inputValue, setInputValue] = useState('');

  const debouncedInputValue = useDebouncedValue(inputValue);

  const { isLoading, data: options = [] } =
    useGetSearchPatients(debouncedInputValue);

  const handleInputChange = (_e: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleClickOption: MouseEventHandler = (e) => {
    // Block Autocomplete from updating the text input value.
    e.stopPropagation();
  };

  const printEnabled = useFeatureFlag('print_button_in_new_navbar');
  const columns = printEnabled ? 5 : 6;
  const PaperWithHeader = printEnabled
    ? PaperWithHeaderPrintEnabled
    : PaperWithHeaderPrintNotEnabled;

  return (
    <Autocomplete
      clearOnBlur={false}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={getOptionLabel}
      // @ts-expect-error - incompatible prop types
      PaperComponent={options.length ? PaperWithHeader : Paper}
      PopperComponent={StyledPopper}
      filterOptions={(x) => x} // Disable Autocomplete's built-in filtering
      renderInput={(params) => (
        <StyledInput
          data-pendo-id="navbar-patient-search"
          fullWidth
          inputProps={params.inputProps}
          placeholder="Search patients"
          ref={params.InputProps.ref}
          startAdornment={
            <MagnifyingGlassIcon
              style={{ width: '16px', height: '16px', marginRight: '6px' }}
            />
          }
        />
      )}
      noOptionsText="No patients found"
      renderOption={(props, option) => {
        return (
          // @ts-expect-error - incompatible prop types
          <Box
            {...props}
            component="li"
            key={option.id}
            onClick={handleClickOption}
          >
            <Grid key={option.id} container columnSpacing={COLUMN_SPACING}>
              <Grid
                component="a"
                item
                xs={columns}
                href={`/patient/${option.id}/summary_v2`}
                sx={{
                  color: 'text.primary',
                  ':hover': { color: 'text.primary' },
                }}
                data-pendo-id={pendoId('view-patient')}
              >
                <Stack component="span">
                  <span>{getOptionLabel(option)}</span>
                  <Typography
                    component="span"
                    variant="bodysmall"
                    lineHeight="1rem"
                  >
                    {safeFormatDate(option.dateOfBirth)}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                component="a"
                item
                xs={columns}
                href={`/patient/${option.id}/summary_v2`}
                sx={{
                  color: 'text.primary',
                  ':hover': { color: 'text.primary' },
                }}
                data-pendo-id={pendoId('view-patient')}
              >
                {option.provider.name}
              </Grid>

              {printEnabled && (
                <Grid
                  item
                  component="span"
                  xs={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="secondary"
                    size="small"
                    sx={{ color: 'text.link', width: '81px' }}
                    href={`/print_summary/?patient=${option.id}&source=search`}
                    data-pendo-id={pendoId('print-patient')}
                  >
                    Print
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        );
      }}
      loading={Boolean(inputValue && isLoading)}
      sx={sx}
    />
  );
}
