import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Typography,
  accordionSummaryClasses,
  css,
  styled,
} from '@mui/material';
import { partition } from 'lodash';
import { format } from 'date-fns';
import { useState } from 'react';

import useFeatureFlag from '@/utils/useFeatureFlag';
import { actionShape } from '@/pages/MedicationManagement/hooks/useGetPatients';
import { stepShape } from '@/utils/transformFrontendCareGapAction';
import { userInputTaskShape } from '@/utils/transformFrontendUserInputTask';
import { ReactComponent as CheckTrueIcon } from '@/assets/icons/checkTrue.svg';
import { useQueryParameters } from '@/contexts/QueryParametersContext';
import TocHistory from '@/pages/TaskBasedWorkflow/components/taskDetailView/TocHistory';
import TocTriggerEvent from '@/pages/TaskBasedWorkflow/components/taskDetailView/TocTriggerEvent';
import ActionCardHeader from '@/pages/TaskBasedWorkflow/components/taskDetailView/ActionCardHeader';
import PatientExternalCareGapPanel from '@/pages/TaskBasedWorkflow/components/patientDetailView/actionCard/PatientExternalCareGapPanel';
import ActionUpdatedAlert from '@/pages/TaskBasedWorkflow/components/ActionUpdatedAlert';
import { relatedActionShape } from '@/utils/relatedActions';
import ProgramEnrollmentFollowUpInformationBanner from '@/pages/TaskBasedWorkflow/components/ProgramEnrollmentFollowUpInformationBanner';
import LearnMoreWidget from '@/pages/TaskBasedWorkflow/components/LearnMoreWidget';

import {
  CHART_UPLOAD,
  COORDINATE,
  PRIORITY_PATIENT_VISIT,
  PROGRAM_ENROLLMENT,
  TRANSITION_TIMEOUT,
  UPDATE_ELIGIBILITY_WINDOW,
} from '../../constants';
import FollowUpWidget from '../FollowUpWidget';
import HideForNowModal from '../HideForNowModal';
import RemoveActionWidget from '../patientDetailView/actionCard/RemoveActionWidget';
import StepList from '../patientDetailView/actionCard/StepList';
import NotesAndRecentActivity from '../patientDetailView/actionCard/NotesAndRecentActivity';
import PriorityPatientStats from '../patientDetailView/actionCard/PriorityPatientStats';
import PriorityScoreBadge from '../PriorityScoreBadge';

import ContextualInfo from './ContextualInfo';
import {
  getContextualInfoConfiguration,
  isVisitFollowUpAction,
} from './ContextualInfo/Configuration';

const OTHER = 'other';
const today = format(new Date(), 'MM-dd-yyyy');

function isChartUploadAction(action) {
  return action.subtype === CHART_UPLOAD;
}

function isPriorityPatientAction(action) {
  return action.subtype === PRIORITY_PATIENT_VISIT;
}

function isProgramEnrollmentAction(action) {
  return action.subtype === PROGRAM_ENROLLMENT;
}

function isTOCAction(action) {
  return action.type === COORDINATE;
}

function getPreviousActionText(action) {
  const { category } = action.v2Dto.contextualInfo;
  const date = action.v2Dto.contextualInfo.mostRecentDateForDisplay;
  const providerName = action.v2Dto.contextualInfo.mostRecentProviderForDisplay;

  // We don't want this text to be displayed for Meds actions since we specify different
  // header text that includes a History dropdown button.
  return category && date && providerName && category !== 'Medications'
    ? `Previous: ${category} on ${date} with ${providerName}`
    : null;
}

export function getCompletionText(
  isActionRemoved,
  lastExecutedStep,
  isActionCompleteWithNextSteps,
) {
  // The completion state is cleared upon page refresh, so assume that the completion date is today.
  const completionDate = today;

  // The action was completed by being closed
  if (isActionRemoved) {
    const { executionInfo } = lastExecutedStep;
    // The removal reason is returned as a tuple of (enum, label).
    const [removalReason, removalReasonLabel] = executionInfo.removalReason;
    const shouldDisplayNotes = Boolean(
      removalReason === OTHER && executionInfo.notes,
    );

    return shouldDisplayNotes
      ? `${removalReasonLabel} (${executionInfo.notes}) - ${completionDate}`
      : `${removalReasonLabel} - ${completionDate}`;
    // Only show additionalText if the removal reason was Other.
  }

  // Else: The action was successfully closed, not removed.
  if (isActionCompleteWithNextSteps) {
    // Available next steps mean claim confirmation
    return `Completed ${completionDate} (awaiting claim for confirmation)`;
  }
  // Else: No claim confirmation step
  return `Completed ${completionDate}`;
}

// store common action functionality and display
export default function ActionCard(props) {
  const {
    action,
    onSelectStep,
    onRemoveStep,
    selectedAction,
    selectedStep,
    sx,
    userInputTask,
    relatedActions,
  } = props;
  const {
    parameters: { taskId, stepId },
    mergeParameters,
  } = useQueryParameters();
  const annualVisitToOfficeVisit = useFeatureFlag(
    'annual_visit_to_office_visit',
  );
  const expanded = taskId === userInputTask.id;
  const previousActionText = getPreviousActionText(action);

  const [stepsToShow, removalSteps] = partition(
    action.v2Dto.availableNextSteps,
    (x) => !x.isRemovalStep,
  );

  const removalStep = removalSteps[0];

  const handleSelectStep = (step) => {
    // Step execution requires both the step id and the action id.
    onSelectStep(step, action);
  };

  const handleChange = () => {
    const defaultNextStep = stepsToShow[0];
    mergeParameters({
      taskId: userInputTask.id,
      actionId: action.id,
      // Update the step execution panel only if it's expanded.
      stepId: stepId ? defaultNextStep?.stepId : undefined,
    });
  };

  const [externalCareGapRecentActivity, addDismissedExternalCareGaps] =
    useState(action?.v2Dto?.contextualInfo?.externalCareGapRecentActivity);
  const [notesAndRecentActivityExpanded, setNotesAndRecentActivityExpanded] =
    useState(false);
  const notesAndRecentActivity = (
    <NotesAndRecentActivity
      expanded={notesAndRecentActivityExpanded}
      setNotesAndRecentActivityExpanded={setNotesAndRecentActivityExpanded}
      flagHistories={userInputTask.flagHistories}
      stepHistories={action?.v2Dto.stepHistories}
      hideForNowHistories={userInputTask.hideForNowHistories}
      legacyNotes={userInputTask.legacyNotes}
      externalCareGaps={externalCareGapRecentActivity}
      sx={{ mt: 1, '&.Mui-expanded:first-of-type': { mt: 1 } }}
    />
  );

  const lastStepHistory =
    action.v2Dto.stepHistories[action.v2Dto.stepHistories.length - 1];
  const actionWasUpdatedByClaims = Boolean(
    lastStepHistory &&
      lastStepHistory.templateStep === UPDATE_ELIGIBILITY_WINDOW,
  );

  const contextualInfoConfigurations = getContextualInfoConfiguration(action);

  return (
    <StyledAccordion
      disableGutters
      expanded={expanded}
      onChange={handleChange}
      sx={sx}
    >
      <StyledAccordionSummary expandIcon={null}>
        <Box display="flex">
          <Box flexShrink={0} mr={1}>
            <PriorityScoreBadge abbreviated score={userInputTask.score} />
          </Box>
          <Typography flexGrow={1}>
            <Typography component="span" variant="headersmallbold">
              {action.v2Dto.description}
            </Typography>{' '}
            {isChartUploadAction(action) || isVisitFollowUpAction(action)
              ? `(due ${action.v2Dto.expiresAt})`
              : null}
            {isPriorityPatientAction(action)
              ? `(due ${action.v2Dto.dueDate})`
              : null}
            {isTOCAction(action) ? `by ${action.v2Dto.completeBy}` : null}
          </Typography>
          <FollowUpWidget
            pendoIdPrefixer={(id) => `worklist-patient-detail-flag-${id}`}
            userInputTask={userInputTask}
          />
          <HideForNowModal
            isHidden={userInputTask.hiddenForNow}
            taskId={userInputTask.id}
          />
          {removalStep ? (
            <RemoveActionWidget
              action={action}
              removalStep={removalStep}
              onRemoveStep={onRemoveStep}
              relatedActions={relatedActions}
            />
          ) : null}
        </Box>

        {!expanded && actionWasUpdatedByClaims && (
          <Box display="block" paddingTop="5px">
            <ActionUpdatedAlert />
          </Box>
        )}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {previousActionText ? (
          <Typography
            color="text.secondary"
            variant="bodysmall"
            display="inline"
          >
            {previousActionText}{' '}
          </Typography>
        ) : null}

        {isTOCAction(action) && <TocHistory actionId={action.id} />}
        {expanded && actionWasUpdatedByClaims && <ActionUpdatedAlert />}
        {isTOCAction(action) && <TocTriggerEvent action={action} />}

        {!annualVisitToOfficeVisit && (
          <LearnMoreWidget
            helpText={action.v2Dto.contextualInfo.helpText}
            title={action.v2Dto.contextualInfo.category}
          />
        )}

        {isPriorityPatientAction(action) ? (
          <PriorityPatientStats action={action} />
        ) : null}
        {isProgramEnrollmentAction(action) && (
          <ProgramEnrollmentFollowUpInformationBanner action={action} />
        )}
        <PatientExternalCareGapPanel
          action={action}
          externalCareGapRecentActivity={externalCareGapRecentActivity}
          addDismissedExternalCareGaps={addDismissedExternalCareGaps}
          setNotesAndRecentActivityExpanded={setNotesAndRecentActivityExpanded}
        />

        {action.v2Dto?.dateOfService &&
        action.v2Dto?.migratedFromId &&
        action.v2Dto?.stepHistories.length === 0 ? (
          <Box sx={{ mt: 1 }}>
            <Alert severity="info">
              <Typography
                component="div"
                variant="bodymedium"
                sx={{ paddingBottom: '1rem' }}
              >
                We recently updated our system and some things may temporarily
                look a bit different than usual. We’ve saved what you’ve done so
                far.
              </Typography>
              <Typography variant="bodymedium">
                This visit is scheduled for{' '}
                <Typography component="span" variant="bodymediumbold">
                  {action.v2Dto?.dateOfService}
                </Typography>
                .
              </Typography>
            </Alert>
          </Box>
        ) : null}

        {contextualInfoConfigurations ? (
          <ContextualInfo
            action={action}
            configurations={contextualInfoConfigurations}
          />
        ) : null}

        <ActionCardHeader action={action} />
        <StepList
          action={action}
          steps={stepsToShow}
          stepHistories={action.v2Dto.stepHistories}
          selectedAction={selectedAction}
          selectedStep={selectedStep}
          onSelect={handleSelectStep}
          preferredPath={action.v2Dto.preferredPath}
        />
        {notesAndRecentActivity}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

ActionCard.propTypes = {
  action: actionShape.isRequired,
  onRemoveStep: PropTypes.func.isRequired,
  onSelectStep: PropTypes.func.isRequired,
  selectedAction: actionShape,
  selectedStep: stepShape,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  userInputTask: userInputTaskShape.isRequired,
  relatedActions: PropTypes.arrayOf(relatedActionShape),
};

ActionCard.defaultProps = {
  selectedAction: undefined,
  selectedStep: undefined,
  sx: undefined,
  relatedActions: [],
};

export const ActionCardSection = styled('div')`
  padding: ${(p) => p.theme.spacing(2, 3)};

  & + & {
    border-top: 1px solid;
    border-color: ${(p) => p.theme.palette.border.base};
  }
`;

export const StyledCheckTrueIcon = styled(CheckTrueIcon)`
  width: 32px;
  height: 32px;
  color: ${(p) => p.theme.palette.status.successForeground};
`;

const borderCss = css`
  &:not(:first-of-type) {
    border-top: 1px solid ${(p) => p.theme.palette.border.base};
  }
  &:last-of-type {
    border-bottom: 1px solid ${(p) => p.theme.palette.border.base};
  }
`;

export const CompletedCardRoot = styled('div')`
  ${borderCss}
`;

const StyledAccordion = styled(Accordion)`
  background-color: ${(p) =>
    p.expanded ? p.theme.palette.background.base : 'transparent'};
  border-radius: 0 !important;
  transition: background-color ${TRANSITION_TIMEOUT}ms;

  &:before {
    display: none;
  }

  ${borderCss}
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: ${(p) => p.theme.spacing(2, 3)};
  transition: padding-bottom ${TRANSITION_TIMEOUT}ms;

  &.${accordionSummaryClasses.expanded} {
    padding-bottom: 0;
  }

  .${accordionSummaryClasses.content} {
    display: block;
    margin: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: ${(p) => p.theme.spacing(0, 3, 1)};
`;
