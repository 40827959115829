import { camelCase, mapKeys, snakeCase } from 'lodash';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import queryString, { ParsedQuery } from 'query-string';

// @ts-expect-error - js module
import useHistory from '@/utils/useHistory';
import baseGetQueryParameters from '@/utils/getQueryParameters';

function getQueryParameters() {
  // Grab all the query parameters and convert the keys from snake_case to camelCase.
  return mapKeys(baseGetQueryParameters(), (_value, key) => camelCase(key));
}

export type QueryParamValue =
  | boolean
  | number
  | string
  | Array<boolean | number | string>
  | null
  | undefined; // will cause the query param to be removed from the url

// The array format must be 'bracket' in order for us to correctly synchronize arrays.
// Otherwise, arrays with lengths of one may be parsed as individual values.
// E.g. ?office_ids=1 -> Is this an number or array?
const ARRAY_FORMAT = 'bracket';

type QueryParametersContextType = {
  /**
   * The URL's query parameters.
   */
  parameters: ParsedQuery<string | boolean | number>;

  /**
   * Set the new parameters, removing any existing query parameters.
   */
  setParameters: (params: Record<string, QueryParamValue>) => void;

  /**
   * Merge the new parameters with any existing query parameters.
   */
  mergeParameters: (params: Record<string, QueryParamValue>) => void;
};

export const QueryParametersContext =
  createContext<QueryParametersContextType | null>(null);

export function useQueryParameters() {
  const context = useContext(QueryParametersContext);

  if (!context) {
    throw new Error(
      'Unexpectedly called `useQueryParameters()` outside of `<QueryParametersProvider>`.',
    );
  }

  return context;
}

/**
 * Hook to get the value of a query parameter.
 *
 * The returned value is not guaranteed to be the correct type.
 * It's up to the consumer to handle type validation if it's important to do so.
 */
export function useQueryParameter<T = QueryParamValue>(
  key: string,
): T | undefined;
export function useQueryParameter<T>(key: string, defaultValue: T): T;
export function useQueryParameter<T>(
  key: string,
  defaultValue?: T,
): T | undefined {
  const { parameters } = useQueryParameters();
  return useMemo(() => {
    const value = parameters[key];
    return typeof value === 'undefined' ? defaultValue : (value as T);

    // Don't recalculate the value if defaultValue changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, parameters]);
}

/**
 * Context provider that synchronizes the context state with the URL's query parameters.
 * The URL is treated as the source of truth. The value of this component is being able
 * to preserve page state across page refreshes and browser back/forward navigation.
 *
 * This component makes the following assumptions:
 * 1. Query parameters can only be strings, numbers, booleans, or an array of any of the previous types.
 * 2. Parameter keys use camelCase in code and snake_case in the URL.
 * 3. If a parameter value is undefined, null, or empty array; it will be omited from the url.
 */
export function QueryParametersProvider({
  children = undefined,
}: {
  children?: ReactNode;
}) {
  const [parameters, setParameters] = useState(
    // The URL is the source of truth, so getQueryParameters() should always be the initial state.
    // If you need to change the initial state, you should either enter the page with the correct query params, or
    // you should call setParameters() inside useEffectOnce().
    () => getQueryParameters(),
  );
  const history = useHistory();

  const setParametersProxy = useCallback(
    (input: Record<string, QueryParamValue>) => {
      const newParameters = { ...input };
      const newUrl = history.getCurrentLocation();
      newUrl.search = queryString.stringify(
        mapKeys(newParameters, (_value, key) => snakeCase(key)),
        {
          arrayFormat: ARRAY_FORMAT,
        },
      );

      history.push(newUrl);
      setParameters(getQueryParameters());
    },
    [history],
  );

  const mergeParameters = useCallback(
    (input: Record<string, QueryParamValue>) => {
      setParametersProxy({
        ...parameters,
        ...input,
      });
    },
    [parameters, setParametersProxy],
  );

  // Update context state in response to the browser navigation buttons.
  useEffect(() => {
    return history.addPopListener(() => {
      setParameters(getQueryParameters());
    });
  }, [history]);

  const contextValue = useMemo(() => {
    return {
      parameters,
      setParameters: setParametersProxy,
      mergeParameters,
    };
  }, [parameters, mergeParameters, setParametersProxy]);

  return (
    <QueryParametersContext.Provider value={contextValue}>
      {children}
    </QueryParametersContext.Provider>
  );
}
