import PropTypes from 'prop-types';

import { maybeFormatDate } from '@/pages/PrintSummary/utils/printUtils';
import { diagnosisShape } from '@/pages/PrintSummary/utils/printPropTypes';
import { isDateThisYear } from '@/pages/PrintSummary/components/ChronicConditionsTable';
import { DX_FLEX_VALUES } from '@/pages/PatientSummary/components/VisitsDxHistory/constants';
import {
  StyledHistoryCell,
  StyledNoData,
  StyledPatientHistoryHeaderRow,
  StyledPatientHistoryRow,
  StyledPatientHistoryTable,
  StyledPatientRowText,
  StyledPatientTabHeader,
  StyledTotalSummaryRow,
} from '@/pages/PatientSummary/components/VisitsDxHistory/styles';
import LoadingModalTable from '@/pages/PatientSummary/components/VisitsDxHistory/LoadingModalTable';

function DxHistoryRow({ diagnosis, num }) {
  const assessedThisYear = isDateThisYear(diagnosis.diagnosisDate);
  const lastDiagnosedAs = [diagnosis.diagnosisCode, diagnosis.description].join(
    ' - ',
  );
  const conditionCategory = diagnosis?.hcc?.name ?? '--';

  return (
    <StyledPatientHistoryRow num={num}>
      <StyledHistoryCell flex={DX_FLEX_VALUES[0]}>
        <StyledPatientRowText>{conditionCategory}</StyledPatientRowText>
      </StyledHistoryCell>
      <StyledHistoryCell flex={DX_FLEX_VALUES[1]}>
        <StyledPatientRowText>{lastDiagnosedAs}</StyledPatientRowText>
      </StyledHistoryCell>
      <StyledHistoryCell flex={DX_FLEX_VALUES[2]}>
        <StyledPatientRowText>
          {maybeFormatDate(diagnosis.diagnosisDate)}
        </StyledPatientRowText>
      </StyledHistoryCell>
      <StyledHistoryCell flex={DX_FLEX_VALUES[3]}>
        <StyledPatientRowText>
          {assessedThisYear ? 'Yes' : 'No'}
        </StyledPatientRowText>
      </StyledHistoryCell>
      <StyledHistoryCell flex={DX_FLEX_VALUES[4]}>
        <StyledPatientRowText>
          {diagnosis.provider?.name || '--'}
        </StyledPatientRowText>
      </StyledHistoryCell>
    </StyledPatientHistoryRow>
  );
}

function DxHistorySummary({ diagnoses, isLoading }) {
  return (
    <StyledPatientHistoryTable>
      <StyledTotalSummaryRow>
        {diagnoses?.length || 0} in last 3 years
      </StyledTotalSummaryRow>
      <StyledPatientHistoryHeaderRow>
        <StyledHistoryCell flex={DX_FLEX_VALUES[0]}>
          <StyledPatientTabHeader>Category</StyledPatientTabHeader>
        </StyledHistoryCell>
        <StyledHistoryCell flex={DX_FLEX_VALUES[1]}>
          <StyledPatientTabHeader>Last diagnosed as</StyledPatientTabHeader>
        </StyledHistoryCell>
        <StyledHistoryCell flex={DX_FLEX_VALUES[2]}>
          <StyledPatientTabHeader>Date Diagnosed</StyledPatientTabHeader>
        </StyledHistoryCell>
        <StyledHistoryCell flex={DX_FLEX_VALUES[3]}>
          <StyledPatientTabHeader>Assessed this year?</StyledPatientTabHeader>
        </StyledHistoryCell>
        <StyledHistoryCell flex={DX_FLEX_VALUES[4]}>
          <StyledPatientTabHeader>Assessed by</StyledPatientTabHeader>
        </StyledHistoryCell>
      </StyledPatientHistoryHeaderRow>

      {isLoading ? (
        <StyledPatientHistoryRow num={2}>
          <LoadingModalTable flexDims={DX_FLEX_VALUES} />
        </StyledPatientHistoryRow>
      ) : null}
      {!isLoading && diagnoses?.length ? (
        diagnoses?.map((dx, i) => (
          <DxHistoryRow
            key={`${dx.diagnosisCode}-${i + 1}`}
            diagnosis={dx}
            num={i}
          />
        ))
      ) : (
        <StyledPatientHistoryRow num={2} p={1}>
          <StyledNoData>No diagnosis history data available.</StyledNoData>
        </StyledPatientHistoryRow>
      )}
    </StyledPatientHistoryTable>
  );
}

DxHistorySummary.propTypes = {
  diagnoses: PropTypes.arrayOf(diagnosisShape),
  isLoading: PropTypes.bool,
};

DxHistorySummary.defaultProps = {
  diagnoses: [],
  isLoading: false,
};

DxHistoryRow.propTypes = {
  diagnosis: diagnosisShape,
  num: PropTypes.number,
};

DxHistoryRow.defaultProps = {
  num: 1,
  diagnosis: [],
};

export default DxHistorySummary;
export { DxHistoryRow };
