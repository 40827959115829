import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { noop } from 'lodash';

import { UserInputTask } from '@/utils/types';

export type BulkExecuteStepsPayload<
  T = Record<string, string | number | boolean | undefined>,
> = Record<
  number, // an action id
  {
    step_id: string; // the step to execute
    execution_input: T;
  }
>;

export type BulkExecuteStepsResult = {
  data: Array<UserInputTask>;
};

function bulkExecuteSteps(payload: BulkExecuteStepsPayload) {
  return axios.post<BulkExecuteStepsResult>('/api/tbw/steps/bulk-execute', {
    bulk_update: payload,
  });
}

export default function useBulkExecuteSteps({
  onSuccess = noop,
  onError = noop,
}: {
  onSuccess: (data: AxiosResponse<BulkExecuteStepsResult>) => void;
  onError?: () => void;
}) {
  return useMutation(bulkExecuteSteps, {
    onSuccess,
    onError,
  });
}
