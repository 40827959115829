import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

export default function LoadingModalTable({ flexDims }) {
  return (
    <Box
      sx={{
        width: '100%',
      }}
      aria-label="Loading modal data"
    >
      {[1, 2, 3].map((i) => (
        <Box
          key={i}
          sx={{
            pb: 1.5,
            display: 'flex',
          }}
        >
          {flexDims.map((k, index) => (
            <Box key={`${k}-${index + 1}`} sx={{ flexGrow: k, pl: 0, pr: 1 }}>
              <Skeleton height={16} variant="rectangular" />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

LoadingModalTable.propTypes = {
  flexDims: PropTypes.arrayOf(PropTypes.number),
};

LoadingModalTable.defaultProps = {
  flexDims: [],
};
