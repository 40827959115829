import Box from '@mui/material/Box';
import styled from 'styled-components';

const TableBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.background.base};
  border: ${(props) => props.theme.spacing(0.1)} solid
    ${(props) => props.theme.palette.border.base};
  border-radius: ${(props) => props.theme.spacing(1)};
  margin: ${(props) => props.theme.spacing(3)};
`;

export default TableBox;
