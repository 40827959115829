import { InternalPerformanceMetric } from '../types';

import useGetGoals from './useGetGoals';

export default function useGetGoal(
  internalPerformanceMetric: InternalPerformanceMetric,
  { enabled = true } = {},
) {
  return useGetGoals({
    enabled,
    select: (data) =>
      data.find(
        (x) => x.internalPerformanceMetric === internalPerformanceMetric,
      ),
  });
}
