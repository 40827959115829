import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function startOutreach({
  patientId,
  patientPhoneNumber,
  providerId,
  providerOfficePhoneNumber,
  actionId,
}) {
  return axios.post(`/api/patient/${patientId}/outreach/start`, {
    patient_phone_number: patientPhoneNumber,
    provider_id: providerId,
    provider_office_phone_number: providerOfficePhoneNumber,
    action_id: actionId,
  });
}

export default function useStartOutreach({ onSuccess = undefined } = {}) {
  return useMutation(startOutreach, {
    onSuccess,
  });
}
