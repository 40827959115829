import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const IncludeVisitAndDxHistoryContext = createContext([false, () => {}]);

export function IncludeVisitAndDxHistoryProvider({ children, initialValue }) {
  const [isIncludeVisitAndDxHistory, setIncludeVisitAndDxHistory] =
    useState(initialValue);
  const toggle = () => setIncludeVisitAndDxHistory((t) => !t);
  const contextValue = useMemo(() => {
    return {
      isIncludeVisitAndDxHistory,
      toggle,
    };
  }, [isIncludeVisitAndDxHistory]);

  return (
    <IncludeVisitAndDxHistoryContext.Provider value={contextValue}>
      {children}
    </IncludeVisitAndDxHistoryContext.Provider>
  );
}

export default function useIncludeVisitAndDxHistory() {
  return useContext(IncludeVisitAndDxHistoryContext);
}

IncludeVisitAndDxHistoryProvider.propTypes = {
  children: PropTypes.node,
  initialValue: PropTypes.bool,
};

IncludeVisitAndDxHistoryProvider.defaultProps = {
  children: undefined,
  initialValue: false,
};
