import PropTypes, { bool, string } from 'prop-types';
import { Button } from '@mui/material';

export default function PatientBannerButton({
  children,
  href,
  onClick,
  sx,
  pendoId,
  disabled,
  endIcon,
}) {
  return (
    <Button
      href={href}
      onClick={onClick}
      sx={sx}
      data-pendo-id={pendoId}
      variant="tertiary"
      disabled={disabled}
      endIcon={endIcon}
    >
      {children}
    </Button>
  );
}

PatientBannerButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  pendoId: string,
  disabled: bool,
  endIcon: PropTypes.element,
};

PatientBannerButton.defaultProps = {
  href: undefined,
  onClick: undefined,
  sx: undefined,
  pendoId: undefined,
  disabled: false,
  endIcon: undefined,
};
