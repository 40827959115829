import Typography from '@mui/material/Typography';
import styled from 'styled-components';

const TitleTypography = styled(Typography)`
  line-height: ${(props) => props.theme.spacing(5)};
  font-size: ${(props) => props.theme.typography.h1.fontSize};
  font-weight: ${(props) => props.theme.typography.h1.fontWeight};
`;

export default TitleTypography;
