// @ts-expect-error -- js file
import useSummaryData from '@/pages/PatientSummaryEhr/hooks/useSummaryData';

export type Patient = {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
};

export type CopyPasteAction = {
  id: number;
  name: string;
  pastIcdCode?: string;
  documentedBy?: string;
  documentedOn?: string;
  possibleCodes: Array<string>;
};

type CopyPasteData = {
  isLoading: boolean;
  isSuccess: boolean;
  isPending: boolean;
  patient?: Patient;
  actions?: Array<CopyPasteAction>;
};

// These are the types expected from useSummaryData.
type NonDiagnosisAction = {
  actionId: number;
  displayName?: string;
  description: string;
};

type CommonCode = {
  raf: boolean;
  icd: string;
};

type RecaptureDiagnosisAction = {
  id: number;
  hcc: {
    name: string;
  };
  // diagnoses is renamed pastDiagnoses from useSummaryData
  pastDiagnoses: Array<{
    icd: string;
    pastDiagnoses: Array<{
      sourceName: string;
      date: string;
    }>;
  }>;
  commonCodes: Array<CommonCode>;
};

type SummaryData = {
  patient: Patient;
  actions: {
    nonDiagnosis: { incomplete: Array<NonDiagnosisAction> };
    recaptureDiagnosis: { incomplete: Array<RecaptureDiagnosisAction> };
  };
  featureFlags: {
    psAllowNonRafDiagnosisCodes: boolean;
    commonDiagnosesWithConditionEnabled: boolean;
  };
  isLoading: boolean;
};

function normalizeNonDiagnosis(action: NonDiagnosisAction): CopyPasteAction {
  return {
    id: action.actionId,
    name: action.displayName ?? action.description,
    possibleCodes: [],
  };
}

function getPastDiagnosis(
  action: RecaptureDiagnosisAction,
): Partial<CopyPasteAction> {
  const [firstDiagnosis] = action.pastDiagnoses ?? [];
  if (firstDiagnosis) {
    const [mostRecent] = firstDiagnosis.pastDiagnoses ?? [];
    if (mostRecent) {
      return {
        pastIcdCode: firstDiagnosis.icd,
        documentedBy: mostRecent.sourceName,
        documentedOn: mostRecent.date,
      };
    }
  }
  return {};
}

function normalizeRecaptureDiagnosis(
  action: RecaptureDiagnosisAction,
  getCommonCodes: (codes: Array<CommonCode>) => Array<string>,
): CopyPasteAction {
  return {
    ...getPastDiagnosis(action),
    id: action.id,
    name: action.hcc.name,
    possibleCodes: getCommonCodes(action.commonCodes),
  };
}

function noCodes() {
  return [];
}

function onlyRafCodes(codes: Array<CommonCode>): Array<string> {
  return codes.filter((code) => code.raf).map((code) => code.icd);
}

function allCodes(codes: Array<CommonCode>): Array<string> {
  return codes.map((code) => code.icd);
}

function getCommonCodesFn(
  commonDiagnosesWithConditionEnabled: boolean,
  psAllowNonRafDiagnosisCodes: boolean,
) {
  if (!commonDiagnosesWithConditionEnabled) {
    return noCodes;
  }

  if (!psAllowNonRafDiagnosisCodes) {
    return onlyRafCodes;
  }

  return allCodes;
}

type Params = {
  patientId: number;
  dateOfService: Date;
  isEnabled: boolean;
};

function useCopyPasteData({
  patientId,
  dateOfService,
  isEnabled,
}: Params): CopyPasteData {
  return useSummaryData({
    patientId,
    dateOfService,
    isEnabled,
    select: ({ patient, actions, featureFlags }: SummaryData) => {
      const {
        psAllowNonRafDiagnosisCodes,
        commonDiagnosesWithConditionEnabled,
      } = featureFlags;

      const { firstName, lastName, dateOfBirth } = patient;

      const { nonDiagnosis, recaptureDiagnosis } = actions;

      const commonCodeFn = getCommonCodesFn(
        commonDiagnosesWithConditionEnabled,
        psAllowNonRafDiagnosisCodes,
      );

      return {
        patient: { firstName, lastName, dateOfBirth },
        actions: [
          ...recaptureDiagnosis.incomplete.map((action) =>
            normalizeRecaptureDiagnosis(action, commonCodeFn),
          ),
          ...nonDiagnosis.incomplete.map(normalizeNonDiagnosis),
        ].filter((action) => action.name != null),
      };
    },
  });
}

export default useCopyPasteData;
