import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import TheProvider from '@/components/TheProvider';

import configureAxiosGlobalDefaults from './utils/configureAxiosGlobalDefaults';

configureAxiosGlobalDefaults();

export default function renderWidget(
  ui,
  elementId = 'app',
  { featureFlags = undefined } = {},
) {
  ReactDOM.render(
    <StrictMode>
      <TheProvider
        featureFlags={{
          notifications_in_new_navbar: Boolean(
            global.notifications_in_new_navbar,
          ),
          print_button_in_new_navbar: Boolean(
            global.print_button_in_new_navbar,
          ),
          ...featureFlags,
        }}
        scopedCss
      >
        {ui}
      </TheProvider>
    </StrictMode>,
    document.getElementById(elementId),
  );
}
