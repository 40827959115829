import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as SHIcon } from '@/assets/icons/sh.svg';

export default function OutreachInfoBanner() {
  return (
    <StyledBox>
      <SHIcon />
      <Typography
        sx={{ fontSize: 'inherit', marginLeft: (theme) => theme.spacing(1) }}
      >
        Stellar Health is reaching out to schedule a visit. 3 attempts will be
        made. See history below for details.
      </Typography>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${(p) => p.theme.spacing(1)};
  color: ${(p) => p.theme.palette.status.infoForeground};
  background-color: ${(p) => p.theme.palette.status.infoBackground};
  border-radius: ${(p) => p.theme.spacing(1)};
  margin-top: ${(p) => p.theme.spacing(1.5)};
`;
