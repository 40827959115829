import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import {
  Box,
  Link,
  dialogContentClasses,
  dialogTitleClasses,
} from '@mui/material';
import { format, isValid, parseISO } from 'date-fns';

// @ts-expect-error -- js file
import BaseModal from '@/components/BaseModal';
import { ReactComponent as PaperclipIcon } from '@/pages/Homepage/components/paperclip.svg';

import useHasNewMessages from '../hooks/useHasNewMessages';
import { CustomMessage } from '../types';

const StyledBaseModal = styled(BaseModal)`
  & .${dialogTitleClasses.root} {
    padding-bottom: 0;
    font-weight: 600;
  }

  & .${dialogContentClasses.root} {
    padding-top: 0;
  }
`;

const StyledPaperclipIcon = styled(PaperclipIcon)(() => ({
  height: 17,
  width: 17,
}));

function parseDate(input: string) {
  const date = parseISO(input);
  return isValid(date) ? date : null;
}

function formatCustomMessageDate(date: string) {
  const parsedDate = parseDate(date);
  if (!parsedDate) {
    return '';
  }
  return format(parsedDate, "EEEE, LLLL do 'at' h:mm aa");
}

type Props = {
  open: boolean;
  onClose: () => void;
  customMessage: CustomMessage;
};

export default function CustomMessageModal({
  open,
  onClose,
  customMessage,
}: Props) {
  const messageTitle = `A message from your Stellar partner, ${customMessage.title}`;
  const [, viewedMessagesCallback] = useHasNewMessages([customMessage]);
  const handleClose = () => {
    viewedMessagesCallback();
    onClose();
  };

  return (
    <StyledBaseModal
      sx={{ width: '720px' }}
      open={open}
      onClose={handleClose}
      dataPendoId="nav_bar_view_message_modal"
      dataDdSyntheticsId="new_homepage_custom_message_modal"
      title={messageTitle}
      disableRestoreFocus
    >
      <Typography
        sx={{ marginBottom: 0 }}
        variant="bodymedium"
        color="status.infoForeground"
      >
        Available until {formatCustomMessageDate(customMessage.endsAt)}
      </Typography>
      <hr style={{ marginTop: 2 }} />
      <Typography variant="bodymedium" color="text.secondary" mt={3}>
        Posted {formatCustomMessageDate(customMessage.startsAt)}
      </Typography>
      <Typography variant="bodylarge" color="text.primary" mt={1} mb={4}>
        {customMessage.message}
      </Typography>
      <Box display="flex" alignItems="center">
        {customMessage.hasDocument && (
          <>
            <StyledPaperclipIcon />
            <Link
              href={`homepage_minimal/custom_messages/${customMessage.id}`}
              sx={{
                color: 'text.link',
                textDecoration: 'underline',
                '&:hover': { color: 'text.link', textDecoration: 'underline' },
              }}
            >
              {customMessage.documentLinkTitle}
            </Link>
          </>
        )}
        {customMessage.customUrlLinkTitle && (
          <Link
            href={customMessage.customUrlLink}
            target="_blank"
            sx={{
              color: 'text.link',
              textDecoration: 'underline',
              '&:hover': { color: 'text.link', textDecoration: 'underline' },
              marginLeft: 'auto',
            }}
          >
            {customMessage.customUrlLinkTitle}
          </Link>
        )}
      </Box>
    </StyledBaseModal>
  );
}
