import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import transformResponse from '@ot/utils/transformResponse';

const providerOfficePhoneNumberKey = 'providerOfficePhoneNumber';

const queryKeyFactory = {
  providerOfficePhoneNumber: (providerId) => [
    providerOfficePhoneNumberKey,
    providerId,
  ],
};

async function getProviderOfficePhoneNumber(providerId) {
  return axios.get(`/api/provider/${providerId}/office/phone_number`, {
    providerId,
  });
}

export default function useGetProviderOfficePhoneNumber(providerId) {
  const { data, isLoading } = useQuery(
    queryKeyFactory.providerOfficePhoneNumber(providerId),
    () => getProviderOfficePhoneNumber(providerId),
    {
      select: transformResponse,
      enabled: !!providerId,
      retry: false,
    },
  );
  return { data, isLoading };
}
