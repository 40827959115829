import { bool, func, instanceOf, node, number, string } from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import CompactPatientSummary from '@/components/CompactPatientSummary';
import BaseSpinner from '@/components/BaseSpinner';
// TODO: break coupling on EHR hooks here:
import useSummaryData from '@/pages/PatientSummaryEhr/hooks/useSummaryData';
import useDiagnosisActionMutator from '@/pages/PatientSummaryEhr/hooks/useDiagnosisActionMutator';
import { FeatureFlagsProvider } from '@/utils/useFeatureFlag';

const today = new Date();

export default function CompactPatientSummaryContainer({
  patientId,
  onSaveNonDiagnosisAction,
  nonDxSaveStatus,
  showCompleted,
  urlFormatter,
  dxDateOfService,
  isDxSubmitDisabled,
  dxDateOfServicePicker,
  shouldLoad = true,
}) {
  const summaryData = useSummaryData({
    patientId,
    dateOfService: today,
    fetchCompleted: showCompleted,
    isEnabled: shouldLoad,
  });

  const isEnabled = Boolean(dxDateOfServicePicker);
  const { mutateAsync: onSaveDiagnosisAction, status: dxSaveStatus } =
    useDiagnosisActionMutator({
      formState: summaryData.formState,
      dateOfService: isEnabled ? dxDateOfService : today,
      patientId,
      urlFormatter,
    });

  const saveStatus = useMemo(
    () => ({ diagnosis: dxSaveStatus, nonDiagnosis: nonDxSaveStatus }),
    [dxSaveStatus, nonDxSaveStatus],
  );

  if (summaryData.isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" flex="1 1">
        <BaseSpinner />
      </Stack>
    );
  }
  if (summaryData.isError) {
    return (
      <Stack justifyContent="center" alignItems="center" flex="1 1">
        <Typography>Could not load patient.</Typography>
      </Stack>
    );
  }

  return (
    <FeatureFlagsProvider value={summaryData.featureFlags} merge>
      <CompactPatientSummary
        actions={summaryData.actions}
        patient={summaryData.patient}
        onSaveDiagnosisAction={onSaveDiagnosisAction}
        onSaveNonDiagnosisAction={onSaveNonDiagnosisAction}
        saveStatus={saveStatus}
        showEhrRibbon={false}
        user={summaryData.user}
        isDxSubmitDisabled={isDxSubmitDisabled}
        dxDateOfServicePicker={dxDateOfServicePicker}
      />
    </FeatureFlagsProvider>
  );
}
CompactPatientSummaryContainer.propTypes = {
  patientId: number,
  showCompleted: bool,
  onSaveNonDiagnosisAction: func.isRequired,
  nonDxSaveStatus: string.isRequired,
  urlFormatter: func,
  dxDateOfService: instanceOf(Date),
  isDxSubmitDisabled: bool,
  dxDateOfServicePicker: node,
  shouldLoad: bool,
};
CompactPatientSummaryContainer.defaultProps = {
  patientId: undefined,
  showCompleted: true,
  urlFormatter: undefined,
  dxDateOfService: undefined,
  isDxSubmitDisabled: false,
  dxDateOfServicePicker: undefined,
  shouldLoad: true,
};
