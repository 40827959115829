import { isValid, parse } from 'date-fns';
import PropTypes from 'prop-types';

import { maybeFormatDate } from '@/pages/PrintSummary/utils/printUtils';

import { diagnosisShape } from '../utils/printPropTypes';
import {
  StyledVisitsAndDxHistoryTable,
  VisitsAndDxHistoryTableColumnLabel,
  VisitsAndDxHistoryTableHeader,
  VisitsAndDxHistoryTableSubHeader,
} from '../utils/printStyles';

export function isDateThisYear(dateStr) {
  const parsed = parse(dateStr, 'yyyy-MM-dd', new Date());
  const thisYear = new Date().getFullYear();
  return isValid(parsed) ? parsed.getFullYear() === thisYear : false;
}

function ChronicConditionRow({ diagnosis }) {
  const conditionCategory = diagnosis.hcc.name ?? '--';
  const lastDiagnosedAs = [diagnosis.diagnosisCode, diagnosis.description].join(
    ' - ',
  );
  const assessedThisYear = isDateThisYear(diagnosis.diagnosisDate);
  const assessedBy = assessedThisYear
    ? diagnosis?.provider?.name || '--'
    : '--';

  return (
    <tr>
      <td>{conditionCategory}</td>
      <td>{lastDiagnosedAs}</td>
      <td>{maybeFormatDate(diagnosis.diagnosisDate)}</td>
      <td>{assessedThisYear ? 'Yes' : 'No'}</td>
      <td>{assessedBy}</td>
    </tr>
  );
}
function ChronicConditionsTable({ diagnoses }) {
  return (
    <StyledVisitsAndDxHistoryTable>
      <thead>
        <tr className="chronic-conditions">
          <td colSpan={5}>
            <VisitsAndDxHistoryTableHeader component="span">
              Chronic Conditions{' '}
            </VisitsAndDxHistoryTableHeader>
            <VisitsAndDxHistoryTableSubHeader component="span">
              {diagnoses.length} in last 3 years
            </VisitsAndDxHistoryTableSubHeader>
          </td>
        </tr>
        <tr>
          <td>
            <VisitsAndDxHistoryTableColumnLabel>
              Category
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
          <td width="30%">
            <VisitsAndDxHistoryTableColumnLabel>
              Last diagnosed as
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
          <td>
            <VisitsAndDxHistoryTableColumnLabel>
              Date diagnosed
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
          <td>
            <VisitsAndDxHistoryTableColumnLabel>
              Assessed this yr?
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
          <td>
            <VisitsAndDxHistoryTableColumnLabel>
              Assessed by
            </VisitsAndDxHistoryTableColumnLabel>
          </td>
        </tr>
      </thead>
      <tbody>
        {diagnoses.map((dx) => (
          <ChronicConditionRow key={dx.id} diagnosis={dx} />
        ))}
      </tbody>
    </StyledVisitsAndDxHistoryTable>
  );
}
export default ChronicConditionsTable;
export { ChronicConditionRow };

ChronicConditionsTable.propTypes = {
  diagnoses: PropTypes.arrayOf(diagnosisShape).isRequired,
};

ChronicConditionRow.propTypes = {
  diagnosis: diagnosisShape.isRequired,
};
