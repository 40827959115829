import styled from 'styled-components';
import { Grid, Link, Typography } from '@mui/material';

export const StyledHistoryCell = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'item', // Ensures 'item' prop is applied directly
})(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`, // Top and bottom spacing
  flexShrink: 1,
  ...theme.typography.bodymedium,
}));

export const StyledPatientHistoryRow = styled(Grid).attrs({
  container: true,
})`
  gap: ${(p) => p.theme.spacing(2)};
  display: flex;
  align-items: flex-start; // Aligns items to the top
  margin: ${(p) => p.theme.spacing(0)} 0; /* 5px top and bottom, 0px left and right */
  padding: ${(p) => p.theme.spacing(1)} 0; /* 5px top and bottom, 0px left and right */
  background-color: ${(props) =>
    props.num && props.num % 2 ? 'none' : 'rgba(251, 251, 254, 1)'};
`;

StyledPatientHistoryRow.defaultProps = {
  num: 1,
};

export const StyledPatientHistoryHeaderRow = styled(StyledPatientHistoryRow)`
  margin: ${(p) => p.theme.spacing(0)};
  padding: ${(p) => p.theme.spacing(0)};
  align-items: flex-end;
`;

export const StyledPatientRowText = styled(Typography).attrs({
  variant: 'bodymedium', // Define the variant here
})``;

export const StyledPatientTabHeader = styled(Typography).attrs({
  variant: 'headerminibold', // Define the variant here
})`
  align-items: flex-end; // Aligns items to the bottom
`;

export const StyledPatientHistoryTable = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'container', // Ensures 'container' prop is applied correctly
})(({ theme }) => ({
  backgroundColor: theme.palette.background.paper, // Use theme background color
  verticalAlign: 'top',
  whiteSpace: 'normal',
  display: 'flex',
  flexDirection: 'column',
  wordBreak: 'break-word',
  flexGrow: 1, // Allows content to fill available space
}));

export const StyledTotalSummaryRow = styled(Grid)(({ theme }) => ({
  fontStyle: 'italic',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  borderBottom: '1px solid rgba(227, 227, 238, 1)',
  width: '100%',
  ...theme.typography.bodymedium,
}));

export const FilterTabsRoot = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  marginTop: theme.spacing(1),
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  gap: theme.spacing(6),
}));
export const StyledTabLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  '&&': {
    ...theme.typography.bodymediumbold,
    color:
      active === 'true' ? theme.palette.text.link : theme.palette.text.primary,
    textDecoration: active === 'true' ? 'underline' : 'none',
    textUnderlineOffset: '6px',
    '&:hover': {
      color: theme.palette.text.link,
      textDecoration: active === 'true' ? 'underline' : 'none',
    },
    cursor: 'pointer',
  },
}));

export const StyledNoteCell = styled(Grid)`
  margin-bottom: ${(p) => p.theme.spacing(3)};
`;

export const StyledModalLink = styled('button')(({ theme }) => ({
  color: theme.palette.text.link, // Adjust this based on your theme structure
  textDecoration: 'underline',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  ...theme.typography.bodylarge, // Spread typography styles
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const StyledNoData = styled(StyledHistoryCell)(({ theme }) => ({
  flexGrow: 10,
  padding: theme.spacing(1),
  minWidth: '1000px',
}));
