import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

async function stopAutomatedOutreach({ patientId, outcome, note }) {
  return axios.post(`/api/patient/${patientId}/stop_automated_outreach`, {
    outcome,
    note,
  });
}

export default function useStopAutomatedOutreach({
  onSuccess = undefined,
} = {}) {
  return useMutation(stopAutomatedOutreach, {
    onSuccess,
  });
}
