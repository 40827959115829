import { Box, ButtonBase, SxProps, styled } from '@mui/material';
import { useState } from 'react';

import { ReactComponent as CustomMessageIcon } from '@/assets/icons/customMessage.svg';

import useHasNewMessages from '../hooks/useHasNewMessages';
import { CustomMessage } from '../types';

import CustomMessageModal from './CustomMessageModal';

const StyledMessageIconEnabled = styled(CustomMessageIcon)(({ theme }) => ({
  height: 50,
  width: 50,
  color: theme.palette.border.primaryActive,
}));

const StyledMessageIconDisabled = styled(CustomMessageIcon)(({ theme }) => ({
  height: 50,
  width: 50,
  color: theme.palette.dataViz.categorical.neutral500,
}));

const NewMessagesDot = styled('div')`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.palette.brand.logo};
`;

type Props = {
  customMessages: Array<CustomMessage>;
  sx?: SxProps;
};

export default function CustomMessageIndicator({
  customMessages,
  sx = {},
}: Props) {
  const [hasNewMessages] = useHasNewMessages(customMessages);
  const [customMessageModalOpen, setCustomMessageModalOpen] = useState(false);
  if (!customMessages) {
    return null;
  }

  if (!customMessages.length) {
    return (
      <Box
        sx={
          [
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '50px',
            },
            sx,
          ] as SxProps
        }
      >
        <StyledMessageIconDisabled />
      </Box>
    );
  }

  return (
    <>
      <ButtonBase
        onClick={() => setCustomMessageModalOpen(true)}
        data-pendo-id="nav_bar_view_message_button"
        data-dd-synthetics-id="new_homepage_view_custom_message"
        disableRipple
        sx={
          [
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '50px',
            },
            sx,
          ] as SxProps
        }
      >
        <Box position="relative" height="50px">
          <StyledMessageIconEnabled />

          {hasNewMessages && (
            <NewMessagesDot sx={{ position: 'absolute', top: -1, right: 1 }} />
          )}
        </Box>
      </ButtonBase>

      <CustomMessageModal
        onClose={() => setCustomMessageModalOpen(false)}
        // we currently only support one custom message
        customMessage={customMessages[0]!}
        open={customMessageModalOpen}
      />
    </>
  );
}
