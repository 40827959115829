import axios from 'axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { useQueryParameter } from '@/contexts/QueryParametersContext';

import { Goal } from '../types';

export type ResponseData = Array<Goal>;

export async function getGoals({ queryKey }: QueryFunctionContext) {
  const [, dateMonth] = queryKey;
  const response = await axios.get<ResponseData>('/api/goals', {
    params: { date_month: dateMonth },
  });
  return response.data;
}

export default function useGetGoals<TData = ResponseData>({
  enabled = true,
  select = undefined,
}: { enabled?: boolean; select?: (data: ResponseData) => TData } = {}) {
  const dateMonth = useQueryParameter('dateMonth');

  return useQuery({
    // @ts-expect-error - not sure why this isn't working
    queryFn: getGoals,
    queryKey: ['goals', dateMonth],
    enabled,
    select,
  });
}
