import styled from 'styled-components';
import Box from '@mui/material/Box';

const TableHeaderBox = styled(Box)`
  padding: ${(props) => props.theme.spacing(2)};
  background-color: ${(props) => props.theme.palette.background.base};
  border-radius: ${(props) => props.theme.spacing()};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default TableHeaderBox;
