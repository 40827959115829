import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { noop } from 'lodash';

async function executeStep({
  actionId,
  stepId,
  executionInput = undefined,
  createPatientVisit = false,
}: {
  actionId: number;
  stepId: string;
  executionInput: unknown;
  createPatientVisit: boolean;
}) {
  return axios.post('/api/tbw/steps/execute', {
    action_id: actionId,
    step_id: stepId,
    execution_input: executionInput,
    create_patient_visit: createPatientVisit,
  });
}

export default function useExecuteStep({ onSuccess = noop } = {}) {
  return useMutation(executeStep, {
    onSuccess,
  });
}

/**
 * Executes multiple executeStep() calls in parallel.
 */
export function useExecuteSteps({
  onSuccess = noop,
  onSettled = noop,
  onError = noop,
  createPatientVisits = false,
}) {
  return useMutation(
    (
      payload: Array<{
        actionId: number;
        stepId: string;
        executionInput: Record<string, unknown>;
      }>,
    ) => {
      return Promise.all(
        payload.map(({ actionId, stepId, executionInput }) => {
          return executeStep({
            actionId,
            stepId,
            executionInput,
            createPatientVisit: createPatientVisits,
          });
        }),
      );
    },
    {
      onSettled,
      onError,
      onSuccess,
    },
  );
}
