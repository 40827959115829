import { styled } from '@mui/material';

import useFeatureFlag from '@/utils/useFeatureFlag';
import { InternalPerformanceMetric } from '@/components/GoalTiles/types';
import useGetGoal from '@/components/GoalTiles/hooks/useGetGoal';

import { Page } from '../../constants';
// @ts-expect-error - js module
import { usePage } from '../../contexts/PageContext';

// @ts-expect-error - js module
import WidgetVisits from './summaryBannerWidget/WidgetVisits';
import WidgetQualityScreenings from './summaryBannerWidget/WidgetQualityScreenings';
import WidgetVisitsCarousel from './summaryBannerWidget/WidgetVisitsCarousel';

const Separator = styled('div')`
  align-self: stretch;
  margin: ${(p) => p.theme.spacing(0, 4)};
  border-left: 1px solid ${(p) => p.theme.palette.border.base};
`;

/**
 * This component is responsible for mapping each page to the correct widget.
 */
export default function SummaryBannerWidget() {
  const page = usePage();
  const goalBannerVisits = useFeatureFlag('goal_banner_visits');
  const { data: acvGoal } = useGetGoal(InternalPerformanceMetric.acv, {
    enabled: goalBannerVisits,
  });

  switch (page) {
    case Page.visits:
      return goalBannerVisits && acvGoal ? (
        <WidgetVisitsCarousel />
      ) : (
        <>
          <Separator />
          <WidgetVisits sx={{ flexBasis: 0, flexGrow: 3, ml: 2 }} />
        </>
      );
    case Page.qualityScreenings:
      return <WidgetQualityScreenings sx={{ ml: 2 }} />;
    default:
      return null;
  }
}
