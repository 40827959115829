import { createContext, useContext } from 'react';

const DateContext = createContext<Date | null>(null);

/**
 * A utility hook to get the current date. The date can be mocked out using DateProvider.
 * @returns
 */
export default function useDate() {
  const date = useContext(DateContext);
  return date ?? new Date();
}

export const DateProvider = DateContext.Provider;
